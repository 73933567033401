<template>
  <div
    :class="` main-navbar  ${
      $vuetify.breakpoint.smAndDown ? 'align-center' : ''
    }`"
  >
    <v-container
      style="padding: 0px"
      class="mw-1150 d-flex justify-space-between"
      :class="$vuetify.breakpoint.smAndDown ? 'align-center' : ''"
    >
      <div class="d-flex pointer" @click="$router.push('/')">
        <v-img
          class="ma-1"
          alt="logo"
          src="../../assets/logo/logo1.png"
          width="48"
          height="48"
          aspect-ratio="1"
          contain
        ></v-img>
        <v-img
          class="ma-1"
          alt="logo"
          src="../../assets/logo/logo2.png"
          width="48"
          height="48"
          aspect-ratio="1"
          contain
        ></v-img>
        <v-img
          class="ma-1"
          alt="logo"
          src="../../assets/logo/logo-purple.png"
          width="48"
          height="48"
          aspect-ratio="1"
          contain
        ></v-img>
      </div>
      <!-- <img
        class="py-2 pointer"
        alt="logo"
        @click="goto('Home')"
        :src="$vuetify.breakpoint.mdAndUp ? logo : smLogo"
      /> -->
      <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex align-center">
        <div
          :class="` ${item.isActive ? 'menu-header-active' : 'menu-header'}`"
          @click="goto(item.link)"
          v-for="(item, i) in menuList"
          :key="i"
        >
          <button
            :style="!item.link ? { color: '#e7e7e7' } : ''"
            :class="`btn mx-4  ${
              item.isActive ? 'btn-menu-header-active' : 'btn-menu-header'
            }`"
          >
            {{ item.name }}
          </button>
        </div>

        <v-btn
          @click="goto('Search')"
          class="mx-3 bg-main bright-white-text"
          small
          elevation="0"
          fab
          ><i class="bi bi-search"></i
        ></v-btn>

        <v-menu bottom offset-y v-if="access_token">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#D8EFFE"
              depressed
              class="mx-3 btn-login"
              rounded
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                v-if="user.image_profile"
                alt="logo"
                width="25"
                class="mx-2 logo"
                aspect-ratio="1"
                :src="storage_path + user.image_profile"
              />
              <img v-else alt="logo" class="mx-2" :src="userIcon" />
              {{ user.name }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="py-0">
            <v-list-item
              style="min-height: 0px; border-bottom: 1px solid #e7e7e7"
              class="pa-0"
              v-for="(item, index) in dropdown_menu"
              :key="index"
            >
              <v-btn
                text
                block
                class="d-flex justify-start ma-0"
                @click="onClickMenu(item.link)"
                v-if="item.link"
              >
                <v-icon color="#4090FF" class="mr-1">{{ item.icon }}</v-icon>
                <span class="font-12">
                  {{ item.title }}
                </span>
              </v-btn>
              <v-btn text block class="d-flex justify-center ma-0" v-else>
                <v-icon color="#4090FF" class="mr-1">{{ item.icon }}</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn class="mx-3 btn-login" rounded to="/login" v-else>
          <img alt="logo" class="mx-2" :src="userIcon" />
          เข้าสู่ระบบ
        </v-btn>
      </div>
      <div v-else>
        <div>
          <v-btn
            @click="goto('Search')"
            class="mx-3 bg-main bright-white-text"
            x-small
            elevation="0"
            fab
            ><i class="bi bi-search"></i
          ></v-btn>
          <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
        </div>
      </div>
      <v-navigation-drawer
        v-if="drawer"
        v-model="drawer"
        right
        absolute
        temporary
      >
        <v-list nav dense>
          <v-list-item-group active-class="deep-purple--text text--accent-4">
            <v-list-item v-for="(item, i) in menuList" :key="i">
              <v-list-item-title @click="goto(item.link)">
                {{ item.name }}
              </v-list-item-title>
            </v-list-item>
            <!-- <v-list-item v-if="access_token">
              <v-list-item-title> โปรไฟล์</v-list-item-title>
            </v-list-item> -->
            <v-list-item v-if="!access_token" @click="$router.push('/login')">
              <v-list-item-title> เข้าสู่ระบบ</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="access_token">
              <v-list-item-title> เปลี่ยนรหัสผ่าน</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="access_token" @click="onLogout">
              <v-list-item-title> ออกจากระบบ</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-container>
    <Loading :loading="isLoading"></Loading>

    <div>
      <v-dialog v-model="dialogQuestionnaire" width="379">
        <questionnaire @setDialog="setQuestionnaire" />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import logo from "../../assets/logo/logo.png";
import smLogo from "../../assets/logo/sm-logo.png";
import userIcon from "../../assets/icon/profile-circle.png";
import { menu } from "../../util/Constant";
import Loading from "../Loading.vue";
import { storage_path } from "../../util/Constant";
import SERVICE from "../../services/index";
import Questionnaire from "../Questionnaire.vue";

export default {
  data() {
    return {
      storage_path,
      dialog: false,
      isLogout: false,
      dialogLogout: false,
      isCheck: true,
      isLoading: false,
      drawer: false,
      logo: logo,
      smLogo: smLogo,
      userIcon: userIcon,
      menuList: menu,
      dropdown_menu: [
        {
          id: 2,
          title: "เปลี่ยนรหัสผ่าน",
          link: "/change-password",
          icon: "mdi-account-key",
        },
        { id: 3, title: "ออกจากระบบ", link: "/logout", icon: "mdi-logout" },
      ],
    };
  },
  components: { Loading, Questionnaire },

  created() {
    this.setActive(this.$route.name);
  },
  watch: {
    $route(to) {
      this.setActive(to.name);
      window.scrollTo(0, 0);
    },
  },
  computed: {
    access_token() {
      return this.$store.state.auth.access_token;
    },
    user() {
      return this.$store.state.auth.user;
    },
    questionnaire() {
      return this.$store.state.auth.questionnaire;
    },
    dialogQuestionnaire() {
      return this.$store.state.auth.dialogQuestionnaire;
    },
  },
  methods: {
    onClickMenu(link) {
      switch (link) {
        case "/profile":
          "";
          break;
        case "/change-password":
          this.$router.push("/change-password");
          break;
        case "/logout":
          this.onLogout();
          break;
        default:
          return false;
      }
    },
    onLogout() {
      // if (!this.getCookie("questionnaire")) {
      //   // this.dialog = true;
      //   this.isLogout = true;
      //   this.$store.commit("auth/setIsQuestionnaire", true);
      // } else {
      //   this.signout();
      // }
      this.signout();
    },
    setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    setQuestionnaire(val) {
      this.setCookie("questionnaire", val, 180);
      // this.dialog = await false;
      this.$store.commit("auth/setIsQuestionnaire", false);
      SERVICE.auth.saveQuestionaire({ questionnaire: val });
      if (this.isLogout) {
        this.signout();
      }
    },
    signout() {
      this.$swal({
        title: "คุณแน่ใจไหม?",
        text: "คุณต้องการออกจากระบบตอนนี้?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4090FF",
        cancelButtonColor: "#BCC4CB",
        confirmButtonText: "ใช่, ออกจากระบบ!",
        cancelButtonText: "ไม่ใช่",
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          this.$store.dispatch("auth/signout").then(() => {
            this.isLoading = false;
            this.$router.push("/login");
          });
        }
      });
    },

    goto(route) {
      if (route && route !== this.$route.name) {
        this.$router.push({ name: route });
      }
    },

    setActive(route) {
      this.menuList.forEach((x) => {
        if (x.link === route || x.subLink === route) x.isActive = true;
        else x.isActive = false;
      });
    },
  },
};
</script>

<style>
.logo {
  border-radius: 100%;
}
</style>
