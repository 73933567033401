var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{staticClass:"bg__change_pass",attrs:{"justify-center":"","align-center":""}},[_c('v-flex',{attrs:{"xs9":"","sm6":"","md5":"","lg4":""}},[_c('Topic',{attrs:{"eng":"Change Password","thai":"เปลี่ยนรหัสผ่าน"}}),_c('v-form',{ref:"form",staticClass:"mt-12 change_password__form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"input__password"},[_c('label',{attrs:{"for":"password"}},[_vm._v("รหัสผ่านปัจจุบัน")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"ระบุรหัสผ่านปัจจุบัน","rules":[(v) => !!v || 'ระบุรหัสผ่านปัจจุบัน'],"append-icon":_vm.form.current_password
              ? _vm.current_password_val
                ? 'mdi-eye'
                : 'mdi-eye-off'
              : '',"type":_vm.current_password_val ? 'password' : 'text'},on:{"click:append":() => (_vm.current_password_val = !_vm.current_password_val)},model:{value:(_vm.form.current_password),callback:function ($$v) {_vm.$set(_vm.form, "current_password", $$v)},expression:"form.current_password"}})],1),_c('div',{staticClass:"input__password"},[_c('label',{attrs:{"for":"password"}},[_vm._v("รหัสผ่านใหม่")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"ระบุรหัสผ่านใหม่","rules":[
            (v) => !!v || 'ระบุรหัสผ่านใหม่',
            (v) =>
              (v && v.length >= 6) ||
              'รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร',
          ],"append-icon":_vm.form.new_password
              ? _vm.new_password_val
                ? 'mdi-eye'
                : 'mdi-eye-off'
              : '',"type":_vm.new_password_val ? 'password' : 'text'},on:{"click:append":() => (_vm.new_password_val = !_vm.new_password_val)},model:{value:(_vm.form.new_password),callback:function ($$v) {_vm.$set(_vm.form, "new_password", $$v)},expression:"form.new_password"}})],1),_c('div',{staticClass:"input__password"},[_c('label',{attrs:{"for":"password"}},[_vm._v("ยืนยันรหัสผ่านใหม่")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"ระบุยืนยันรหัสผ่านใหม่","rules":[
            (v) => !!v || 'ระบุยืนยันรหัสผ่านใหม่',
            (v) =>
              (v && v.length >= 6) ||
              'รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร',
          ],"append-icon":_vm.form.new_confirm_password
              ? _vm.new_confirm_password_val
                ? 'mdi-eye'
                : 'mdi-eye-off'
              : '',"type":_vm.new_confirm_password_val ? 'password' : 'text'},on:{"click:append":() => (_vm.new_confirm_password_val = !_vm.new_confirm_password_val)},model:{value:(_vm.form.new_confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "new_confirm_password", $$v)},expression:"form.new_confirm_password"}})],1),_c('div',{staticClass:"mt-8 d-flex justify-center"},[_c('v-btn',{staticClass:"btn__login",attrs:{"disabled":!_vm.valid,"dark":_vm.valid,"height":"58px"},on:{"click":_vm.validate}},[_c('span',{staticClass:"font-24 font-w-500"},[_vm._v("เปลี่ยนรหัสผ่าน")])])],1),_c('div',{staticClass:"mt-4 text-center d-flex justify-center align-center"},[_c('span',{staticClass:"hover__underline font-24 font-w-500",staticStyle:{"color":"#bcc4cb"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" กลับสู่หน้าหลัก ")])])]),_c('Loading',{attrs:{"loading":_vm.isLoading}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }