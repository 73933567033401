import media from "./api/media";
import auth from "./api/auth";
import chat from "./api/chat";
import cases from "./api/case";
import course from "./api/course"
import eventAndNews from "./api/eventAndNews"
import faq from "./api/faq"
import search from "./api/search"
import banner from "./api/banner"
export default {
  media,
  auth,
  chat,
  cases,
  course,
  eventAndNews,
  faq,
  search,
  banner
};
