import Service from '../../services/index'

const state = {
    list: {
        training_course: [],
        event_new: [],
        knowledge_media: [],
    },
}

const getters = {}
const actions = {
    // eslint-disable-next-line no-unused-vars
    get({ commit }, keyword) {
        return Service.search.get(keyword).then((response) => {
            commit("SET_LIST", response.data);
            return response;
        })
    },
}
const mutations = {
    SET_LIST: (state, data) => {
        if (data.length > 0) {
            const training_course = data.filter(x => x.category === 'training_course')
            const event_new = data.filter(x => x.category === 'event_new')
            const knowledge_media = data.filter(x => x.category === 'knowledge_media')
            state.list = {
                training_course: training_course,
                event_new: event_new,
                knowledge_media: knowledge_media
            }
        }

    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


