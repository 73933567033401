import axios from "axios";
import { domain } from "../util/Constant";
import store from "../store";

const http = new axios.create({
  baseURL: domain,
});

const httpMethod = {
  get(url, options = {}) {
    const token = store.state.auth.access_token;
    if (!token) {
      return Promise.reject("No access token.");
    }

    return http
      .get(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((e) => {
        if (e.response.status === 401) {
          window.localStorage.removeItem("vuex");
          window.location.reload();
        }
      });
  },
  post(url, data = null, options = {}) {
    const token = store.state.auth.access_token;
    if (!token) {
      return Promise.reject("No access token.");
    }

    return http
      .post(url, data, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((e) => {
        if (e.response.status === 401) {
          return e.response.status;
        } else {
          return e;
        }
      });
  },
  put(url, data = null, options = {}) {
    const token = store.state.auth.access_token;
    if (!token) {
      return Promise.reject("No access token.");
    }

    return http
      .put(url, data, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  },
  delete(url, options = {}) {
    const token = store.state.auth.access_token;
    if (!token) {
      return Promise.reject("No access token.");
    }

    return http
      .delete(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  },
};

export default httpMethod;
