var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-footer"},[_c('v-container',{staticClass:"mw-1150"},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{staticClass:"py-6 py-sm-6 py-md-0",attrs:{"xs12":"","sm10":"","md12":""}},[_c('v-row',{staticClass:"align-center",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"lg":"7","md":"8","sm":"12","cols":"12"}},[_c('v-layout',{attrs:{"align-center":"","wrap":""}},[_c('v-flex',{attrs:{"xs9":"","sm6":"","md5":""}},[_c('v-layout',[_c('v-flex',{attrs:{"xs4":""}},[_c('v-img',{staticClass:"ma-1",attrs:{"alt":"logo","src":require("../../assets/logo/logo1.png"),"aspect-ratio":"1","max-height":"60","max-width":"60","contain":""}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-img',{staticClass:"ma-1",attrs:{"alt":"logo","src":require("../../assets/logo/logo2.png"),"aspect-ratio":"1","max-height":"60","max-width":"60","contain":""}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-img',{staticClass:"ma-1",attrs:{"alt":"logo","src":require("../../assets/logo/logo-white.png"),"aspect-ratio":"1","max-height":"60","max-width":"60","contain":""}})],1)],1)],1),_c('v-flex',{staticClass:"mt-6 mt-sm-0",attrs:{"xs12":"","sm6":"","md7":""}},[_c('div',{class:_vm.$vuetify.breakpoint.xs
                      ? 'font-22 font-w-400'
                      : 'font-14 font-w-400'},[_vm._v(" ระบบให้คำปรึกษาและพัฒนาผู้ปฏิบัติงานคุ้มครองเด็กและผู้ถูกกระทำด้วยความรุนแรงในครอบครัว ")]),_c('div',{class:_vm.$vuetify.breakpoint.xs
                      ? 'font-22 font-w-400'
                      : 'font-14 font-w-400',staticStyle:{"color":"#6dcdec"}},[_vm._v(" OSCC.Consulting@gmail.com ")])])],1)],1),_c('v-col',{staticClass:"mt-8 mt-sm-0",attrs:{"lg":"5","md":"4","sm":"12","cols":"12"}},[_c('v-layout',{attrs:{"justify-space-between":""}},[_c('v-spacer'),_c('v-flex',{staticClass:"d-flex justify-end",attrs:{"xs12":"","md10":"","lg7":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"mb-7 mb-sm-0",attrs:{"xs12":"","sm6":""}},[_c('div',{staticClass:"cursor-pointer",class:_vm.$vuetify.breakpoint.xs
                          ? 'font-22 font-w-400'
                          : 'font-14 font-w-400',on:{"click":function($event){return _vm.$router.push('/course')}}},[_vm._v(" หลักสูตรอบรม ")])]),_c('v-flex',{staticClass:"mb-7 mb-sm-0",attrs:{"xs12":"","sm6":""}},[_c('div',{staticClass:"cursor-pointer",class:_vm.$vuetify.breakpoint.xs
                          ? 'font-22 font-w-400'
                          : 'font-14 font-w-400',on:{"click":function($event){return _vm.$router.push('/about')}}},[_vm._v(" เกี่ยวกับเรา ")])]),_c('v-flex',{staticClass:"mb-7 mb-sm-0",attrs:{"xs12":"","sm6":""}},[_c('div',{staticClass:"cursor-pointer",class:_vm.$vuetify.breakpoint.xs
                          ? 'font-22 font-w-400'
                          : 'font-14 font-w-400',on:{"click":function($event){return _vm.$router.push('/event-news')}}},[_vm._v(" กิจกรรมและข่าวสาร ")])]),_c('v-flex',{staticClass:"mb-7 mb-sm-0",attrs:{"xs12":"","sm6":""}},[_c('div',{staticClass:"cursor-pointer",class:_vm.$vuetify.breakpoint.xs
                          ? 'font-22 font-w-400'
                          : 'font-14 font-w-400',on:{"click":function($event){return _vm.$router.push('/faq')}}},[_vm._v(" คำถามที่พบบ่อย ")])]),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('div',{staticClass:"cursor-pointer",class:_vm.$vuetify.breakpoint.xs
                          ? 'font-22 font-w-400'
                          : 'font-14 font-w-400'},[_c('a',{staticClass:"policy",staticStyle:{"color":"#fff"},attrs:{"href":`${_vm.banner_path}PrivacyPolicy.pdf`,"target":"_blank"}},[_vm._v(" นโยบายของเว็บไซต์ ")])])])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }