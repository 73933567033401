import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ChatView from "../views/Chat/ChatView.vue";
import TestChat from "../views/Chat/TestChat.vue";
import CaseForm from "../views/Case/IssueCase/CaseForm.vue";
import Media from "../views/Media.vue";
import MediaList from "../views/MediaList.vue";
import Login from "../views/Auth/Login.vue";
import Register from "../views/Auth/Register.vue";
import PageNotFound from "../views/PageNotFound.vue";
import MentalHealthCare from "../views/Case/MentalHealthCare/MentalHealthCare.vue";
import PersonalAppointmentForm from "../views/Case/AppointmentList/PersonalAppointmentForm.vue";
import PersonalAppointment from "../views/Case/AppointmentList/PersonalAppointment.vue";
import ChangePassword from "../views/Auth/ChangePassword.vue";
import ForgotPassword from "../views/Auth/ForgotPassword.vue";
import ResetPassword from "../views/Auth/ResetPassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/media/:id",
    name: "Media",
    component: Media,
  },
  {
    path: "/media",
    name: "MediaList",
    component: MediaList,
  },
  {
    path: "/chat",
    name: "ChatView",
    component: ChatView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/test-chat",
    name: "TestChat",
    component: TestChat,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/create-case",
    name: "CaseForm",
    component: CaseForm,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/mentor",
    name: "MentalHealthCare",
    component: MentalHealthCare,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/personal-appointment-form",
    name: "PersonalAppointmentForm",
    component: PersonalAppointmentForm,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/personal-appointment",
    name: "PersonalAppointment",
    component: PersonalAppointment,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/course/:id",
    name: "Course",
    component: () => import("../views/Course/Course.vue"),
  },
  {
    path: "/course",
    name: "CourseList",
    component: () => import("../views/Course/CourseList.vue"),
  },
  {
    path: "/course-history",
    name: "CourseHistory",
    component: () => import("../views/Course/CourseHistory.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/event-news/:id",
    name: "EventNews",
    component: () => import("../views/EventAndNews/EventAndNews.vue"),
  },
  {
    path: "/event-news",
    name: "EventNewsList",
    component: () => import("../views/EventAndNews/EventAndNewsList.vue"),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("../views/FAQ.vue"),
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search.vue"),
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (!store.state.auth.access_token) {
      next({
        path: "/login",
      });
    } else {
      // หน้า mentor เข้าได้เฉพาะ role = 1,3,5
      if (to.path === "/mentor" && store.state.auth.user.roles.role_id === 2 && store.state.auth.user.roles.role_id === 4) {
        router.go(-1);
      }
      // หน้า personal-appointment-form เข้าได้เฉพาะ role = 2
      else if (
        to.path === "/personal-appointment-form" &&
        store.state.auth.user.roles.role_id !== 2
      ) {
        router.go(-1);
      } else if (
        to.path === "/create-case" &&
        store.state.auth.user.roles.role_id !== 2
      ) {
        router.go(-1);
      } else if (
        to.path === "/personal-appointment" &&
        store.state.auth.user.roles.role_id !== 2
      ) {
        router.go(-1);
      } else {
        next();
      }
    }
  } else {
    if (store.state.auth.access_token && to.path === "/login") {
      window.location.replace("/");
    } else {
      next();
    }
  }
});

export default router;
