import Service from "../service";
import AuthService from "../AuthService";
const url = "course";
const course = {
  get(data) {
    return Service.get(url, { params: data });
  },
  courseHistory(data) {
    return AuthService.get("get-course-history", { params: data });
  },
  show(model) {
    return Service.get(`${url}/${model.courseId}`, {
      params: { user_id: model.user_id },
    });
  },
  courseHome() {
    return Service.get(`course-home`);
  },
  register(payload) {
    return AuthService.post(`${url}`, payload);
  },
  certificate(payload) {
    return AuthService.get(`certificate-template`, { params: payload });
  },
};
export default course;
