<template>
  <div style="position: relative" v-if="model">
    <Badge
      :isFix="true"
      text="Highlight!"
      :height="32"
      :width="100"
      color="#6DCDEC"
    ></Badge>
    <v-img
      v-if="model && model.image_training_courses"
      :aspect-ratio="16 / 10"
      :src="storage_path + model.image_training_courses"
      style="position: relative; border-radius: 5px"
      class="pointer"
      @click="$router.push(`/course/${model.id}`)"
    >
      <v-layout class="shadow__course px-3 pb-4" justify-center align-end>
        <v-flex>
          <div class="font-16">{{ model.name }}</div>
          <div class="font-24">
            <i class="bi bi-calendar-minus"></i>
            {{
              changeDate(
                model.open_date_courses,
                model.open_time_courses,
                model.end_time_courses
              )
            }}
          </div>
        </v-flex>
      </v-layout>
    </v-img>
    <v-img
      v-else
      :aspect-ratio="16 / 10"
      src="../../assets/image/no-img-course.png"
      style="position: relative; border-radius: 5px"
      class="pointer"
      @click="$router.push(`/course/${model.id}`)"
    >
      <v-layout class="shadow__course px-3 pb-4" justify-center align-end>
        <v-flex>
          <div class="font-16">{{ model.name }}</div>
          <div class="font-24">
            <i class="bi bi-calendar-minus"></i>
            {{
              changeDate(
                model.open_date_courses,
                model.open_time_courses,
                model.end_time_courses
              )
            }}
          </div>
        </v-flex>
      </v-layout>
    </v-img>
  </div>
</template>

<script>
import { changeDate } from "../../plugins/date-time-convert";
import { storage_path } from "../../util/Constant";
import Badge from "../Badge.vue";
export default {
  components: {
    Badge,
  },
  props: ["model"],
  data() {
    return {
      changeDate: changeDate,
      storage_path: storage_path,
    };
  },
  methods: {
    goto(id) {
      this.$router.push(`/course/${id}`);
    },
  },
};
</script>

<style>
.course-main {
  position: relative;
  display: inline-block;
}

.course-main:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(51, 51, 51, 0) 0%,
    rgba(44, 53, 66, 0.7) 41.67%,
    #28374d 100%
  );
}
.shadow__course {
  background: linear-gradient(
    180deg,
    rgba(51, 51, 51, 0) 0%,
    rgba(44, 53, 66, 0.7) 41.67%,
    #28374d 100%
  );
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  height: 200px;
}
.emblem {
  background: linear-gradient(91.81deg, #c930ff -14.83%, #6e49ec 107.49%);
  border-radius: 5px;
}
</style>
