<template>
  <v-layout justify-center align-center class="bg__register py-12">
    <v-flex xs10 sm7 md6 lg5>
      <Topic eng="Register" thai="สมัครสมาชิก" />

      <!-- form -->
      <v-form
        ref="form"
        class="mt-12 register__form"
        v-model="valid"
        lazy-validation
      >
        <div class="mt-2">
          <label class="font-18 font-w-500">
            <span class="valid">*</span>คำนำหน้า
          </label>
          <v-text-field
            v-model="form.title"
            outlined
            dense
            placeholder="ระบุคำนำหน้า"
            :rules="[(v) => !!v || 'ระบุคำนำหน้า']"
          />
        </div>

        <v-row class="mt-2">
          <v-col cols="12" sm="6" class="py-0">
            <label class="font-18 font-w-500">
              <span class="valid">*</span>ชื่อ
            </label>
            <v-text-field
              v-model="form.first_name"
              outlined
              dense
              placeholder="ระบุชื่อ"
              :rules="[(v) => !!v || 'ระบุชื่อ']"
            />
          </v-col>
          <v-col cols="12" sm="6" class="py-0">
            <label class="font-18 font-w-500">
              <span class="valid">*</span>สกุล
            </label>
            <v-text-field
              v-model="form.last_name"
              outlined
              dense
              placeholder="ระบุสกุล"
              :rules="[(v) => !!v || 'ระบุสกุล']"
            />
          </v-col>
        </v-row>

        <div class="mt-2">
          <label class="font-18 font-w-500">
            <span class="valid">*</span>วิชาชีพ
          </label>
          <v-autocomplete
            v-model="form.profession"
            :items="profession_list"
            outlined
            dense
            item-text="title"
            item-value="id"
            placeholder="ระบุวิชาชีพ"
            :rules="[(v) => !!v || 'ระบุวิชาชีพ']"
          ></v-autocomplete>
          <v-text-field
            v-if="form.profession === 11"
            class="mt-0"
            v-model="form.profession_other"
            outlined
            dense
            placeholder="ระบุวิชาชีพอื่นๆ"
            :rules="[
              ((v) => !!v && form.profession === 11) || 'ระบุวิชาชีพอื่นๆ',
            ]"
          />
        </div>

        <div class="mt-0">
          <label class="font-18 font-w-500">
            <span class="valid">*</span>สังกัด
          </label>
          <v-autocomplete
            v-model="form.institution"
            :items="institution_list"
            outlined
            dense
            item-text="title"
            item-value="id"
            placeholder="ระบุสังกัด"
            :rules="[(v) => !!v || 'ระบุสังกัด']"
          ></v-autocomplete>

          <v-text-field
            v-if="form.institution === 5"
            class="mt-0"
            v-model="form.institution_other"
            outlined
            dense
            placeholder="ระบุสังกัดอื่นๆ"
            :rules="[
              ((v) => !!v && form.institution === 5) || 'ระบุสังกัดอื่นๆ',
            ]"
          />
        </div>

        <div class="mt-0">
          <label class="font-18 font-w-500">
            <span class="valid">*</span>ชื่อหน่วยงาน
          </label>
          <v-text-field
            outlined
            dense
            v-model="form.institution_description"
            :rules="[(v) => !!v || 'ระบุชื่อหน่วยงาน']"
            placeholder="ระบุชื่อหน่วยงาน"
          />
        </div>

        <v-row class="mt-0">
          <v-col cols="12" sm="6" class="py-0">
            <label class="font-18 font-w-500">
              <span class="valid">*</span>เบอร์โทรศัพท์
            </label>
            <v-text-field
              v-model="form.mobile_phone"
              :oninput="returnInputRule"
              outlined
              dense
              placeholder="ระบุเบอร์โทรศัพท์"
              :rules="[
                (v) => !!v || 'ระบุเบอร์โทรศัพท์',
                (v) =>
                  (v && v.length == 10) || 'รหัสผ่านต้องมีความยาว 10 ตัวอักษร',
              ]"
            />
          </v-col>
          <v-col cols="12" sm="6" class="py-0">
            <label class="font-18 font-w-500">
              <span class="valid">*</span>อีเมล
            </label>
            <v-text-field
              v-model="form.email"
              outlined
              dense
              type="email"
              placeholder="ระบุอีเมล"
              :rules="[
                (v) => !!v || 'ระบุอีเมล',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
              ]"
            />
          </v-col>
        </v-row>

        <div class="mt-4 input__password">
          <label for="password" class="font-18 font-w-500">
            <span class="valid">*</span>รหัสผ่าน
            <span style="color: #bcc4cb" class="font-1">
              (กรอกรหัสผ่านอย่างน้อย 6 ตัว)
            </span>
          </label>
          <v-text-field
            v-model="form.password"
            outlined
            type="password"
            dense
            placeholder="ระบุรหัสผ่าน"
            :rules="[
              (v) => !!v || 'ระบุรหัสผ่าน',
              (v) => (v && v.length >= 6) || 'กรอกรหัสผ่านอย่างน้อย 6 ตัว',
            ]"
          />
        </div>

        <div class="mt-12 d-flex justify-center">
          <v-btn
            class="btn__login"
            :disabled="!valid"
            :dark="valid"
            @click="validate"
            height="58px"
          >
            <span class="font-24 font-w-500">สมัครสมาชิก</span>
          </v-btn>
        </div>

        <div class="mt-4 text-center font-24 font-w-500" style="color: #4090ff">
          <span class="hover__underline" @click="$router.push('/login')">
            ย้อนกลับ
          </span>
        </div>
      </v-form>

      <Loading :loading="isLoading"></Loading>
    </v-flex>
  </v-layout>
</template>

<script>
import Topic from "../../components/Topic.vue";
import { domain } from "../../util/Constant";
import Axios from "axios";
import Loading from "../../components/Loading.vue";
export default {
  components: { Topic, Loading },

  data() {
    return {
      valid: true,
      isLoading: false,
      form: {
        title: "",
        first_name: "",
        last_name: "",
        profession: "",
        profession_other: "",
        institution: "",
        institution_other: "",
        institution_description: "",
        mobile_phone: "",
        email: "",
        password: "",
      },
      profession_list: [],
      institution_list: [],
    };
  },
  computed: {
    returnInputRule: function () {
      return "value=value.replace(/[^\\d]/g,'')";
    },
  },
  async created() {
    // call api
    this.isLoading = true;
    const { data } = await Axios.get(`${domain}/list-register`);
    this.profession_list = await data.professions;
    this.institution_list = await data.institutions;
    this.isLoading = false;
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        Axios.post(`${domain}/register`, this.form)
          .then(() => {
            this.$swal({
              title: "สมัครสมาชิกเรียบร้อย",
              text: "เจ้าหน้าที่จะส่งข้อความยืนยัน การอนุมัติการเป็นสมาชิกไปที่อีเมลของท่าน ภายใน 3 วันทำการ",
              icon: "success",
              confirmButtonText: "ตกลง",
            }).then(() => {
              this.$router.push("/login");
            });
            this.isLoading = false;
          })
          .catch((e) => {
            this.isLoading = false;
            this.$swal({
              title: "สมัครสมาชิกไม่สำเร็จ",
              text: e.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
.bg__register {
  background: linear-gradient(180deg, #ffffff 0%, #edf0f6 100%);
  min-height: calc(100vh - 136px);
  color: #5e616f;
}
.register__form {
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-top: 0px;
    margin-bottom: 0px !important;
  }
}

.btn__login {
  width: 342px;
  background: linear-gradient(
    91.81deg,
    #4950ec -14.83%,
    #4090ff 48.24%,
    #6dcdec 107.49%
  );
  border-radius: 30px;
}
.forgot__pass {
  color: #bcc4cb;
  cursor: pointer;
}
.forgot__pass a:hover {
  text-decoration: underline;
}
.input__password {
  .theme--light.v-input input,
  .theme--light.v-input textarea {
    color: #4090ff;
  }
}
.valid {
  color: red;
}
</style>
