import Service from "../../services/index";

const state = {
  access_token: null,
  user: null,
  questionnaire: false,
  isQuestionnaire: false,
  dialogQuestionnaire: false,
  isLogout: false,
  countRequest: 0,
};

const getters = {};

const mutations = {
  SET_AUTH(state, data) {
    state.access_token = data.token;
    state.user = data.user;
  },
  CLEAR_TOKEN(state, data) {
    state.access_token = null;
    state.user = null;
  },
  setIsQuestionnaire(state, payload) {
    state.dialogQuestionnaire = payload;
  },
  setiIsLogout(state, payload) {
    state.isLogout = payload;
  },
  set_questionnaire(state) {
    state.questionnaire = true;
  },
  add_count_request(state) {
    state.countRequest++;
  },
  remove_count_request(state) {
    state.countRequest--;
  },
};

const actions = {
  signin({ commit }, data) {
    return Service.auth
      .signin(data)
      .then((res) => {
        if (res.status === 200) {
          commit("SET_TOKEN", res);
        }
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  },
  signout({ commit }) {
    return Service.auth.signout().then((res) => {
      commit("CLEAR_TOKEN");
      return res;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
