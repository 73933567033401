<template>
  <div class="personal_appointment">
    <v-container class="mb-16">
      <v-layout align-center mb-8 wrap>
        <v-flex shrink>
          <v-img
            src="../../../assets/images/personal-appointment/logo-personal-appointment.png"
          ></v-img>
        </v-flex>
        <v-flex class="px-4">
          <div class="font-32 font-w-500">บริการพิเศษสำหรับสมาชิก</div>
          <div class="font-16 font-w-400">
            การนัดพบที่ปรึกษาส่วนตัว ทั้งปัญหาครอบครัว งาน ความสัมพันธ์
            สุขภาพจิตและอื่นๆ
          </div>
        </v-flex>
        <v-flex shrink class="mt-3 mt-sm-0">
          <v-btn
            :disabled="!isShow"
            depressed
            :class="isShow ? 'btn_blue_gradient' : ''"
            to="/personal-appointment-form"
          >
            <v-icon color="#fff">mdi-plus</v-icon>
            <span class="white--text">สร้างนัดหมายปรึกษาปัญหาส่วนตัว</span>
          </v-btn>
        </v-flex>
      </v-layout>

      <Topic
        :thai="topic.title_th === 'ทั้งหมด' ? 'สำหรับสมาชิก' : topic.title_th"
        :eng="topic.title_en"
      />

      <!-- Tab -->
      <div class="tab mt-6">
        <!-- Head tab -->
        <v-layout justify-space-between align-center>
          <v-flex shrink class="ml-n8 ml-sm-n3">
            <v-tabs v-model="tab" background-color="transparent">
              <v-tab
                v-for="(item, i) in tabs_staff"
                :key="i"
                @click="onClickTab(item)"
              >
                <span style="color: #5e616f" class="font-18 font-w-500">
                  {{ item.title_th }}
                </span>
              </v-tab>
            </v-tabs>
          </v-flex>
        </v-layout>

        <div class="tab_item mt-8">
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, i) in tabs_staff" :key="i">
              <!-- Tab 1 ทั้งหมด -->
              <div class="tab_1" v-if="tab === 0">
                <div v-for="(item, i) in cases" :key="i">
                  <v-layout
                    wrap
                    class="card_item px-4 py-3 mb-3"
                    v-if="item.type"
                  >
                    <!-- Icon -->
                    <v-flex xs12 sm1 class="pr-6">
                      <v-img
                        v-if="item.type === 'ปรึกษาเคส'"
                        width="40"
                        contain
                        aspect-ratio="1"
                        src="../../../assets/images/personal-appointment/icon-case.png"
                      ></v-img>
                      <v-img
                        v-if="item.type === 'ปรึกษาปัญหาส่วนตัว'"
                        width="40"
                        height="40"
                        contain
                        aspect-ratio="1"
                        src="../../../assets/images/personal-appointment/icon-personal-appointment.png"
                      ></v-img>
                      <v-img
                        v-if="item.type === 'หลักสูตรอบรม'"
                        width="40"
                        aspect-ratio="1"
                        contain
                        src="../../../assets/images/personal-appointment/icon-caurse.png"
                      ></v-img>
                    </v-flex>

                    <v-flex xs12 sm11>
                      <v-layout align-center>
                        <v-flex
                          xs3
                          lg3
                          md5
                          class="txt_gray font-18 font-w-500"
                          v-if="$vuetify.breakpoint.smAndUp"
                        >
                          Link Zoom:
                        </v-flex>
                        <v-flex
                          shrink
                          class="px-0 px-sm-3 font-14 font-w-400 ell-1"
                        >
                          <span
                            class="txt_purple"
                            v-if="item.type === 'ปรึกษาเคส'"
                          >
                            <a
                              v-if="item.zoom_url"
                              :href="item.zoom_url"
                              target="_blank"
                            >
                              <span class="txt_purple">
                                {{ item.zoom_url }}
                              </span>
                            </a>
                            <span v-else class="txt_purple">
                              ยังไม่มีลิ้งค์
                            </span>
                          </span>
                          <span
                            class="txt_blue"
                            v-if="item.type === 'ปรึกษาปัญหาส่วนตัว'"
                          >
                            <a
                              v-if="item.zoom_url"
                              :href="item.zoom_url"
                              target="_blank"
                            >
                              <span class="txt_blue">
                                {{ item.zoom_url }}
                              </span>
                            </a>
                            <span v-else class="txt_blue">
                              ยังไม่มีลิ้งค์
                            </span>
                          </span>
                          <span
                            class="txt_green"
                            v-if="item.type === 'หลักสูตรอบรม'"
                          >
                            <a
                              v-if="item.course && item.course.link_zoom"
                              :href="item.course.link_zoom"
                              target="_blank"
                            >
                              <span class="txt_green">
                                {{ item.course.link_zoom }}
                              </span>
                            </a>
                            <span v-else class="txt_green">
                              ยังไม่มีลิ้งค์
                            </span>
                          </span>
                        </v-flex>
                        <v-flex shrink>
                          <v-btn
                            depressed
                            dark
                            color="#5E616F"
                            max-height="22"
                            v-if="item.zoom_url"
                            @click="copy(item.zoom_url)"
                          >
                            คัดลอก
                          </v-btn>
                          <v-btn
                            depressed
                            dark
                            color="#5E616F"
                            max-height="22"
                            v-if="item.course && item.course.link_zoom"
                            @click="copy(item.course.link_zoom)"
                          >
                            คัดลอก
                          </v-btn>
                        </v-flex>
                      </v-layout>

                      <v-layout class="mt-3" wrap>
                        <v-flex sm3 xs6>
                          <div class="txt_gray font-18 font-w-500">ประเภท</div>
                          <div>{{ item.type }}</div>
                        </v-flex>

                        <v-flex sm3 xs6 class="mb-3 mb-sm-0">
                          <div class="txt_gray font-18 font-w-500">วันเวลา</div>
                          <div class="font-14 font-w-400">
                            {{ checkDate(item) }}
                          </div>
                          <div class="font-14 font-w-400">
                            {{ checkTime(item) }}
                          </div>
                        </v-flex>

                        <v-flex sm3 xs6>
                          <div
                            class="txt_gray font-18 font-w-500"
                            v-if="item.type === 'หลักสูตรอบรม'"
                          >
                            หลักสูตรอบรม
                          </div>
                          <div
                            v-if="item.type === 'ปรึกษาปัญหาส่วนตัว'"
                            class="txt_gray font-18 font-w-500"
                          >
                            ผู้ให้คำปรึกษา
                          </div>
                          <div v-else class="txt_gray font-18 font-w-500">
                            ผู้เชี่ยวชาญ
                          </div>

                          <div v-if="item.type === 'ปรึกษาเคส'">
                            <v-layout>
                              <v-flex
                                shrink
                                v-for="(
                                  image, i
                                ) in item.mentor_appointment_chat"
                                :key="i"
                                class="mr-n3"
                              >
                                <v-img
                                  style="border-radius: 100%"
                                  width="35"
                                  aspect-ratio="1"
                                  v-if="
                                    image.mentor && image.mentor.image_profile
                                  "
                                  :src="
                                    storage_path + image.mentor.image_profile
                                  "
                                ></v-img>
                                <v-img
                                  width="35"
                                  v-else
                                  src="../../../assets/images/default-image-profile.png"
                                ></v-img>
                              </v-flex>
                            </v-layout>
                          </div>

                          <div v-if="item.type === 'ปรึกษาปัญหาส่วนตัว'">
                            <v-img
                              style="border-radius: 100%"
                              aspect-ratio="1"
                              width="35"
                              v-if="item.mentor && item.mentor.image_profile"
                              :src="storage_path + item.mentor.image_profile"
                            ></v-img>
                            <v-img
                              width="35"
                              v-else
                              src="../../../assets/images/default-image-profile.png"
                            ></v-img>
                          </div>

                          <div v-if="item.type === 'หลักสูตรอบรม'">
                            <v-img
                              :aspect-ratio="4 / 3"
                              width="50"
                              style="border: 1px solid #e3e3e3"
                              v-if="
                                item.course &&
                                item.course.image_training_courses
                              "
                              :src="
                                storage_path +
                                item.course.image_training_courses
                              "
                            ></v-img>
                            <v-img
                              width="50"
                              v-else
                              src="../../../assets/images/default-image.png"
                            ></v-img>
                          </div>
                        </v-flex>

                        <!-- สถานะ -->
                        <v-flex sm3 xs6>
                          <div class="txt_gray font-18 font-w-500">สถานะ</div>

                          <!-- ปรึกษาปัญหาส่วนตัว -->
                          <div
                            class="font-14 font-w-400"
                            v-if="
                              item.type === 'ปรึกษาปัญหาส่วนตัว' ||
                              item.type === 'ปรึกษาเคส'
                            "
                          >
                            <div v-if="item.zoom_url">
                              <div
                                v-if="
                                  checkStatusDate(item) === 'ถึงเวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  ถึงเวลานัดหมาย
                                </span>
                                <v-icon color="#80c843" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>
                              <div
                                v-else-if="
                                  checkStatusDate(item) === 'ใกล้เวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  ใกล้เวลานัดหมาย
                                </span>
                                <v-icon color="#FFE0E0" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>

                              <div
                                v-else-if="
                                  checkStatusDate(item) === 'หมดเวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  หมดเวลานัดหมาย
                                </span>
                                <v-icon color="red" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>

                              <div v-else>
                                <span class="font-14 font-w-400 mr-2">
                                  รอการนัดหมาย
                                </span>
                                <v-icon color="#4090ff" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>
                            </div>
                            <div v-else>
                              <span class="font-14 font-w-400 mr-2">
                                นัดหมายใหม่
                              </span>
                              <span
                                style="
                                  background-color: #f6c517;
                                  border-radius: 5px;
                                  color: #fff;
                                "
                                class="font-12 font-w-500 pl-1 pr-2"
                              >
                                ใหม่!
                              </span>
                            </div>
                          </div>

                          <!-- หลักสูตรอบรม -->
                          <div
                            class="font-14 font-w-400"
                            v-if="item.type === 'หลักสูตรอบรม'"
                          >
                            <div v-if="item.link_zoom">
                              <div
                                v-if="
                                  checkStatusDate(item) === 'ถึงเวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  ถึงเวลานัดหมาย
                                </span>
                                <v-icon color="#80c843" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>
                              <div
                                v-else-if="
                                  checkStatusDate(item) === 'ใกล้เวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  ใกล้เวลานัดหมาย
                                </span>
                                <v-icon color="#FFE0E0" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>

                              <div
                                v-else-if="
                                  checkStatusDate(item) === 'หมดเวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  หมดเวลานัดหมาย
                                </span>
                                <v-icon color="red" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>

                              <div v-else>
                                <span class="font-14 font-w-400 mr-2">
                                  รอการนัดหมาย
                                </span>
                                <v-icon color="#4090ff" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>
                            </div>

                            <div v-else>
                              <span class="font-14 font-w-400 mr-2">
                                นัดหมายใหม่
                              </span>
                              <span
                                style="
                                  background-color: #f6c517;
                                  border-radius: 5px;
                                  color: #fff;
                                "
                                class="font-12 font-w-500 pl-1 pr-2"
                              >
                                ใหม่!
                              </span>
                            </div>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </div>

              <!-- Tab 2 ปรึกษาเคส-->
              <div class="tab_2" v-if="tab === 1">
                <div v-for="(item, i) in appointmentCases" :key="i">
                  <v-layout
                    v-if="item.type"
                    wrap
                    class="card_item px-4 py-3 mb-3"
                  >
                    <!-- Icon -->
                    <v-flex xs12 sm1 class="mr-6">
                      <v-img
                        width="40"
                        aspect-ratio="1"
                        src="../../../assets/images/personal-appointment/icon-case.png"
                      ></v-img>
                    </v-flex>

                    <v-flex xs12 sm11>
                      <v-layout align-center>
                        <v-flex
                          xs3
                          lg3
                          md5
                          class="txt_gray font-18 font-w-500"
                          v-if="$vuetify.breakpoint.smAndUp"
                        >
                          Link Zoom:
                        </v-flex>

                        <v-flex shrink class="mx-3 font-14 font-w-400 ell-1">
                          <a
                            v-if="item.zoom_url"
                            :href="item.zoom_url"
                            target="_blank"
                          >
                            <span class="txt_purple">
                              {{ item.zoom_url }}
                            </span>
                          </a>
                          <span v-else class="txt_purple">
                            ยังไม่มีลิ้งค์
                          </span>
                        </v-flex>

                        <v-flex shrink v-if="item.zoom_url">
                          <v-btn
                            depressed
                            dark
                            color="#5E616F"
                            max-height="22"
                            @click="copy(item.zoom_url)"
                          >
                            คัดลอก
                          </v-btn>
                        </v-flex>
                      </v-layout>

                      <v-layout class="mt-3" wrap>
                        <v-flex xs6 sm3>
                          <div class="txt_gray font-18 font-w-500">ประเภท</div>
                          <div>{{ item.type }}</div>
                        </v-flex>
                        <v-flex xs6 sm3>
                          <div class="txt_gray font-18 font-w-500">วันเวลา</div>
                          <div class="font-14 font-w-400">
                            {{ checkDate(item) }}
                          </div>
                          <div class="font-14 font-w-400">
                            {{ checkTime(item) }}
                          </div>
                        </v-flex>
                        <v-flex xs6 sm3>
                          <div class="txt_gray font-18 font-w-500">
                            ผู้เชี่ยวชาญ
                          </div>

                          <v-layout>
                            <v-flex
                              shrink
                              v-for="(image, i) in item.mentor_appointment_chat"
                              :key="i"
                              class="mr-n3"
                            >
                              <v-img
                                style="border-radius: 100%"
                                aspect-ratio="1"
                                width="35"
                                v-if="
                                  image.mentor && image.mentor.image_profile
                                "
                                :src="storage_path + image.mentor.image_profile"
                              ></v-img>
                              <v-img
                                width="35"
                                v-else
                                src="../../../assets/images/default-image-profile.png"
                              ></v-img>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <!-- สถานะ -->
                        <v-flex xs6 sm3>
                          <div class="txt_gray font-18 font-w-500">สถานะ</div>

                          <div class="font-14 font-w-400">
                            <div v-if="item.zoom_url">
                              <div
                                v-if="
                                  checkStatusDate(item) === 'ถึงเวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  ถึงเวลานัดหมาย
                                </span>
                                <v-icon color="#80c843" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>
                              <div
                                v-else-if="
                                  checkStatusDate(item) === 'ใกล้เวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  ใกล้เวลานัดหมาย
                                </span>
                                <v-icon color="#FFE0E0" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>

                              <div
                                v-else-if="
                                  checkStatusDate(item) === 'หมดเวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  หมดเวลานัดหมาย
                                </span>
                                <v-icon color="red" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>

                              <div v-else>
                                <span class="font-14 font-w-400 mr-2">
                                  รอการนัดหมาย
                                </span>
                                <v-icon color="#4090ff" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>
                            </div>
                            <div v-else>
                              <span class="font-14 font-w-400 mr-2">
                                นัดหมายใหม่
                              </span>
                              <span
                                style="
                                  background-color: #f6c517;
                                  border-radius: 5px;
                                  color: #fff;
                                "
                                class="font-12 font-w-500 pl-1 pr-2"
                              >
                                ใหม่!
                              </span>
                            </div>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </div>

              <!-- Tab 3 ส่วนตัว -->
              <div class="tab_3" v-if="tab === 2">
                <div v-for="(item, i) in personalAppointmentCases" :key="i">
                  <v-layout
                    class="card_item px-4 py-3 mb-3"
                    wrap
                    v-if="item.type"
                  >
                    <!-- Icon -->
                    <v-flex xs12 sm1 class="pr-6">
                      <v-img
                        width="40"
                        aspect-ratio="1"
                        contain
                        src="../../../assets/images/personal-appointment/icon-personal-appointment.png"
                      ></v-img>
                    </v-flex>

                    <v-flex xs12 sm11>
                      <v-layout align-center>
                        <v-flex
                          xs3
                          lg3
                          md5
                          class="txt_gray font-18 font-w-500"
                          v-if="$vuetify.breakpoint.smAndUp"
                        >
                          Link Zoom:
                        </v-flex>
                        <v-flex
                          shrink
                          class="px-0 px-sm-3 font-14 font-w-400 ell-1"
                        >
                          <a
                            v-if="item.zoom_url"
                            :href="item.zoom_url"
                            target="_blank"
                          >
                            <span class="txt_blue">
                              {{ item.zoom_url }}
                            </span>
                          </a>
                          <span v-else class="txt_blue"> ยังไม่มีลิ้งค์ </span>
                        </v-flex>
                        <v-flex
                          shrink
                          class="d-flex justify-end"
                          v-if="item.zoom_url"
                        >
                          <v-btn
                            depressed
                            dark
                            color="#5E616F"
                            max-height="22"
                            @click="copy(item.zoom_url)"
                          >
                            คัดลอก
                          </v-btn>
                        </v-flex>
                      </v-layout>

                      <v-layout class="mt-3" wrap>
                        <v-flex xs6 sm3>
                          <div class="txt_gray font-18 font-w-500">ประเภท</div>
                          <div>{{ item.type }}</div>
                        </v-flex>
                        <v-flex xs6 sm3>
                          <div class="txt_gray font-18 font-w-500">วันเวลา</div>
                          <div class="font-14 font-w-400">
                            {{ checkDate(item) }}
                          </div>
                          <div class="font-14 font-w-400">
                            {{ checkTime(item) }}
                          </div>
                        </v-flex>
                        <v-flex xs6 sm3>
                          <div class="txt_gray font-18 font-w-500">
                            ผู้ให้คำปรึกษา
                          </div>

                          <v-img
                            style="border-radius: 100%"
                            aspect-ratio="1"
                            width="35"
                            v-if="item.mentor && item.mentor.image_profile"
                            :src="storage_path + item.mentor.image_profile"
                          ></v-img>
                          <v-img
                            width="35"
                            v-else
                            src="../../../assets/images/default-image-profile.png"
                          ></v-img>
                        </v-flex>

                        <!-- สถานะ -->
                        <v-flex xs6 sm3>
                          <div class="txt_gray font-18 font-w-500">สถานะ</div>

                          <!-- ปรึกษาปัญหาส่วนตัว -->
                          <div class="font-14 font-w-400">
                            <div v-if="item.zoom_url">
                              <div
                                v-if="
                                  checkStatusDate(item) === 'ถึงเวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  ถึงเวลานัดหมาย
                                </span>
                                <v-icon color="#80c843" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>
                              <div
                                v-else-if="
                                  checkStatusDate(item) === 'ใกล้เวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  ใกล้เวลานัดหมาย
                                </span>
                                <v-icon color="#FFE0E0" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>

                              <div
                                v-else-if="
                                  checkStatusDate(item) === 'หมดเวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  หมดเวลานัดหมาย
                                </span>
                                <v-icon color="red" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>

                              <div v-else>
                                <span class="font-14 font-w-400 mr-2">
                                  รอการนัดหมาย
                                </span>
                                <v-icon color="#4090ff" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>
                            </div>
                            <div v-else>
                              <span class="font-14 font-w-400 mr-2">
                                นัดหมายใหม่
                              </span>
                              <span
                                style="
                                  background-color: #f6c517;
                                  border-radius: 5px;
                                  color: #fff;
                                "
                                class="font-12 font-w-500 pl-1 pr-2"
                              >
                                ใหม่!
                              </span>
                            </div>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </div>

              <!-- Tab 4 หลักสูตร -->
              <div class="tab_4" v-if="tab === 3">
                <div v-for="(item, i) in courses" :key="i">
                  <v-layout
                    class="card_item px-4 py-3 mb-3"
                    wrap
                    v-if="item.type"
                  >
                    <!-- Icon -->
                    <v-flex xs12 sm1 class="pr-6">
                      <v-img
                        v-if="item.type === 'หลักสูตรอบรม'"
                        width="40"
                        :aspect-ratio="1"
                        src="../../../assets/images/personal-appointment/icon-caurse.png"
                      ></v-img>
                    </v-flex>

                    <v-flex xs12 sm11>
                      <v-layout align-center>
                        <v-flex
                          xs3
                          lg3
                          md5
                          class="txt_gray font-18 font-w-500"
                          v-if="$vuetify.breakpoint.smAndUp"
                        >
                          Link Zoom:
                        </v-flex>
                        <v-flex
                          shrink
                          class="px-0 px-sm-3 font-14 font-w-400 ell-1"
                        >
                          <a
                            v-if="item.course && item.course.link_zoom"
                            :href="item.course.link_zoom"
                            target="_blank"
                          >
                            <span class="txt_green">
                              {{ item.course.link_zoom }}
                            </span>
                          </a>
                          <span v-else class="txt_green"> ยังไม่มีลิ้งค์ </span>
                        </v-flex>
                        <v-flex
                          shrink
                          v-if="item.course && item.course.link_zoom"
                        >
                          <v-btn
                            depressed
                            dark
                            color="#5E616F"
                            max-height="22"
                            @click="copy(item.course.link_zoom)"
                          >
                            คัดลอก
                          </v-btn>
                        </v-flex>
                      </v-layout>

                      <v-layout class="mt-3" wrap>
                        <v-flex xs6 sm3>
                          <div class="txt_gray font-18 font-w-500">ประเภท</div>
                          <div>{{ item.type }}</div>
                        </v-flex>
                        <v-flex xs6 sm3>
                          <div class="txt_gray font-18 font-w-500">วันเวลา</div>
                          <div class="font-14 font-w-400">
                            {{ checkDate(item) }}
                          </div>
                          <div class="font-14 font-w-400">
                            {{ checkTime(item) }}
                          </div>
                        </v-flex>
                        <v-flex xs6 sm3>
                          <div class="txt_gray font-18 font-w-500">
                            หลักสูตรอบรม
                          </div>

                          <v-img
                            width="50"
                            style="border: 1px solid #e3e3e3"
                            :aspect-ratio="4 / 3"
                            v-if="
                              item.course && item.course.image_training_courses
                            "
                            :src="
                              storage_path + item.course.image_training_courses
                            "
                          ></v-img>
                          <v-img
                            width="50"
                            v-else
                            src="../../../assets/images/default-image.png"
                          ></v-img>
                        </v-flex>

                        <!-- สถานะ -->
                        <v-flex xs6 sm3>
                          <div class="txt_gray font-18 font-w-500">สถานะ</div>
                          <div class="font-14 font-w-400">
                            <div v-if="item.link_zoom">
                              <div
                                v-if="
                                  checkStatusDate(item) === 'ถึงเวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  ถึงเวลานัดหมาย
                                </span>
                                <v-icon color="#80c843" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>
                              <div
                                v-else-if="
                                  checkStatusDate(item) === 'ใกล้เวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  ใกล้เวลานัดหมาย
                                </span>
                                <v-icon color="#FFE0E0" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>

                              <div
                                v-else-if="
                                  checkStatusDate(item) === 'หมดเวลานัดหมาย'
                                "
                              >
                                <span class="font-14 font-w-400 mr-2">
                                  หมดเวลานัดหมาย
                                </span>
                                <v-icon color="red" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>

                              <div v-else>
                                <span class="font-14 font-w-400 mr-2">
                                  รอการนัดหมาย
                                </span>
                                <v-icon color="#4090ff" small>
                                  mdi-checkbox-blank-circle
                                </v-icon>
                              </div>
                            </div>

                            <div v-else>
                              <span class="font-14 font-w-400 mr-2">
                                นัดหมายใหม่
                              </span>
                              <span
                                style="
                                  background-color: #f6c517;
                                  border-radius: 5px;
                                  color: #fff;
                                "
                                class="font-12 font-w-500 pl-1 pr-2"
                              >
                                ใหม่!
                              </span>
                            </div>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </v-container>

    <Loading :loading="isLoading"></Loading>
  </div>
</template>

<script>
import Topic from "../../../components/Topic.vue";
import moment from "moment";
import SERVICE from "../../../services/index";
import Loading from "../../../components/Loading.vue";
import { storage_path } from "../../../util/Constant";
export default {
  components: {
    Topic,
    Loading,
  },
  data() {
    return {
      isShow: true,
      cases: [],
      storage_path: storage_path,
      appointmentCases: [],
      personalAppointmentCases: [],
      courses: [],
      paginate: {
        current_page: 1,
        total: "",
        last_page: "",
      },
      isLoading: false,
      topic: {
        tab: 1,
        title_en: "For Member",
        title_th: "ทั้งหมด",
      },
      tab: 0,
      tabs_staff: [
        { tab: 1, title_th: "ทั้งหมด", title_en: "For Member" },
        { tab: 2, title_th: "ปรึกษาเคส", title_en: "Case" },
        {
          tab: 3,
          title_th: "ปรึกษาปัญหาส่วนตัว",
          title_en: "Personal Consultation Appointment",
        },
        {
          tab: 4,
          title_th: "หลักสูตรอบรม",
          title_en: "Course",
        },
      ],
    };
  },
  created() {
    this.getCases();
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    getCases() {
      this.cases = [];
      this.isLoading = true;
      SERVICE.cases
        .getCases()
        .then((res) => {
          this.cases = res;
          // console.log("data", this.cases);

          // check disable ปุ่มสร้างนัดหมายปรึกษาปัญหาส่วนตัว
          const isCases = this.cases.filter(
            (item) => item.type === "ปรึกษาปัญหาส่วนตัว"
          );
          if (isCases.length > 0) {
            this.isShow = false;
          } else {
            this.isShow = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    getAppointmentCases(page) {
      this.isLoading = true;
      this.appointmentCases = [];
      SERVICE.cases
        .getAppointmentCases(page)
        .then((res) => {
          this.appointmentCases = res;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    getPersonalAppointmentCases(page) {
      this.isLoading = true;
      this.personalAppointmentCases = [];
      SERVICE.cases
        .getPersonalAppointmentCases(page)
        .then((res) => {
          this.personalAppointmentCases = res;
          // this.paginate = {
          //   current_page: res.current_page,
          //   total: res.total,
          //   last_page: res.last_page,
          // };
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    getCourses(page) {
      this.isLoading = true;
      this.courses = [];
      SERVICE.cases
        .getCourses(page)
        .then((res) => {
          this.courses = res;
          // this.paginate = {
          //   current_page: res.current_page,
          //   total: res.total,
          //   last_page: res.last_page,
          // };
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    checkDate(item) {
      if (item.type === "ปรึกษาปัญหาส่วนตัว" || item.type === "ปรึกษาเคส") {
        return moment(item.date).format("DD-MM-YYYY");
      }
      if (item.type === "หลักสูตรอบรม") {
        return item.course && item.course.open_date_courses
          ? moment(item.course.open_date_courses).format("DD-MM-YYYY")
          : "";
      }
    },

    checkTime(item) {
      if (item.type === "ปรึกษาปัญหาส่วนตัว" || item.type === "ปรึกษาเคส") {
        return item.start_time + "-" + item.end_time;
      }
      if (item.type === "หลักสูตรอบรม") {
        return item.course
          ? item.course.open_time_courses + "-" + item.course.end_time_courses
          : "";
      }
    },

    checkStatusDate(item) {
      const dateNow = moment(new Date()).format("YYYY-MM-DD");
      const timeNow = moment(new Date()).format("H:mm");

      //! Case ปรึกษาปัญหาส่วนตัว or ปรึกษาเคส
      if (item.type === "ปรึกษาปัญหาส่วนตัว" || item.type === "ปรึกษาเคส") {
        // ถ้าถึงวันนัดหมาย
        if (dateNow === item.date) {
          // ถ้าถึงเวลานัดหมาย
          if (timeNow >= item.start_time && timeNow <= item.end_time) {
            return "ถึงเวลานัดหมาย";
          }
          // เลยระยะเวลาสิ้นสุดนัดหมาย
          else if (timeNow >= item.start_time && timeNow > item.end_time) {
            return "หมดเวลานัดหมาย";
          }
          // ถ้ายังไม่ถึงเวลานัดหมาย
          else {
            return "ใกล้เวลานัดหมาย";
          }
        }
        // ยังไม่ถึงวันนัดหมาย
        if (dateNow < item.date) {
          return "รอการนัดหมาย";
        }
      }

      //! Case หลักสูตรอบรม
      if (item.type === "หลักสูตรอบรม") {
        // ถ้าถึงวันนัดหมาย
        if (dateNow === item.course.open_date_courses) {
          // ถ้าถึงเวลานัดหมาย
          if (
            timeNow >= item.course.open_time_courses &&
            timeNow <= item.course.end_time_courses
          ) {
            return "ถึงเวลานัดหมาย";
          }
          // เลยระยะเวลาสิ้นสุดนัดหมาย
          else if (
            timeNow >= item.course.open_time_courses &&
            timeNow > item.course.end_time_courses
          ) {
            return "หมดเวลานัดหมาย";
          }
          // ถ้ายังไม่ถึงเวลานัดหมาย
          else {
            return "ใกล้เวลานัดหมาย";
          }
        }
        // ยังไม่ถึงวันนัดหมาย
        if (dateNow < item.course.open_date_courses) {
          return "รอการนัดหมาย";
        }
      }
    },

    onClickTab(item) {
      this.topic = item;
      if (item.tab === 1) {
        this.getCases(1);
      }
      if (item.tab === 2) {
        this.getAppointmentCases(1);
      }
      if (item.tab === 3) {
        this.getPersonalAppointmentCases(1);
      }
      if (item.tab === 4) {
        this.getCourses(1);
      }
    },

    copy(link) {
      navigator.clipboard.writeText(link);
      this.$swal({
        title: "คัดลอกแล้ว",
        icon: "success",
        timer: 1000,
        showConfirmButton: false,
      });
    },
  },
};
</script>

<style lang="scss">
.personal_appointment {
  background: linear-gradient(180deg, #ffffff 0%, #edf0f6 100%);
  min-height: calc(100vh - 136px);
  color: #5e616f;
  .card_item {
    background: #ffffff;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  .txt_gray {
    color: #bcc4cb;
  }
  .txt_purple {
    color: #6e49ec;
  }
  .txt_blue {
    color: #4090ff;
  }
  .txt_green {
    color: #80c843;
  }
}
.tab_item {
  .theme--light.v-tabs-items {
    background-color: transparent;
  }
  .v-window {
    overflow: unset !important;
  }
}
</style>