<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :min-width="$vuetify.breakpoint.xs ? '150px' : '213px'"
      :min-height="$vuetify.breakpoint.xs ? '150px' : '213px'"
      height="100%"
      width="100%"
      :class="`py-9 py-sm-10 d-flex flex-column align-center pointer  ${
        hover ? `on-hover on-hover-${image}` : ''
      }`"
      style="border-radius: 10px"
    >
      <v-img
        style="border-radius: 0px"
        contain
        max-width="100%"
        :src="require('../assets/icon/menu/' + image + '2.png')"
      />
      <div
        :class="$vuetify.breakpoint.xs ? 'font-16' : 'font-20'"
        class="`mt-5 font-w-500`"
      >
        {{ name }}
      </div>
      <div v-if="desc" class="font-12 font-w-500" style="color: #cdcdcd">
        ({{ desc }})
      </div>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: ["image", "name", "desc"],
};
</script>

<style scoped>
.on-hover {
  background-color: #edf0f6 !important;
}
.on-hover-course {
  color: #80c843;
}
.on-hover-event {
  color: #ffd233;
}
.on-hover-media {
  color: #009982;
}
.on-hover-faq {
  color: #4090ff;
}
.on-hover-member {
  color: #b1d8fe;
}
</style>
