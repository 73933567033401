<template>
  <div class="media__menu">
    <Loading :loading="isLoading"></Loading>
    <v-hover v-slot="{ hover }">
      <v-card
        @click="filterCategory"
        class="px-2 py-2 pointer"
        :elevation="hover ? 1 : 1"
      >
        <v-layout class="d-flex align-center">
          <v-flex>
            <v-img
              width="40px"
              height="40px"
              :src="require(`@/assets/mock/media_panel_${id}.png`)"
            ></v-img>
          </v-flex>
          <v-flex>
            <div
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'font-24 text-black'
                  : 'font-18 text-black'
              "
            >
              {{ name }}
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import Loading from "../Loading.vue";
export default {
  props: ["id", "name"],
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    Loading,
  },
  methods: {
    async filterCategory() {
      this.isLoading = true;
      this.$store.commit("Media/SET_RENDER", true);
      await this.$store.dispatch("Media/get", {
        paginate: 8,
        category_id: this.id,
      });
      this.$store.commit("Media/SET_RENDER", false);
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.media__menu {
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: 0px 3px 5px 0px rgb(64 144 255 / 50%) !important;
    transition: 0.2s ease-in-out;
  }
  .v-sheet.v-card:not(.v-sheet--outlined):hover {
    box-shadow: 0px 0px 0px 0px rgb(64 144 255 / 50%) !important;
  }
}
</style>
