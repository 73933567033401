<template>
  <div class="obj-center">
    <div class="d-flex justify-center">
      <img :src="readMore" />
    </div>
  </div>
</template>

<script>
import readMore from "../assets/icon/readmore.png";
export default {
  data() {
    return {
      readMore: readMore,
    };
  },
};
</script>

<style>
</style>