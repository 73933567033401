<template>
  <div>
    <v-layout justify-center style="position: relative">
      <!-- Chat List -->
      <v-flex
        md12
        lg8
        class="ml-lg-12 ml-md-0 block__card__list"
        style="border-left: 1px solid #d3d9e3"
      >
        <div>
          <div class="d-flex align-center">
            <v-row align="center" class="px-3 py-5">
              <v-col cols="12" sm="4" class="head_case_list">
                <v-icon :color="colorRole()"> mdi-file-document-outline</v-icon>
                <span class="font-18 font-w-500 mx-3" style="color: #5e616f">
                  รายการ Case
                </span>
                <span :style="{ color: colorRole() }">
                  ({{ rooms.length }})
                </span>
              </v-col>

              <v-col
                cols="12"
                sm="8"
                class="pl-3"
                v-if="currentRoom"
                :style="
                  $vuetify.breakpoint.xs
                    ? { borderTop: '1px solid rgb(236 237 244)' }
                    : {}
                "
              >
                <v-layout align-center>
                  <v-flex shrink @click="toogleRoomList">
                    <v-icon :color="colorRole()">
                      mdi-comment-processing-outline
                    </v-icon>
                  </v-flex>
                  <v-flex shrink class="font-w-500 font-16 ml-3 ellipsis-1">
                    {{ headChatName }}
                  </v-flex>

                  <v-spacer />

                  <v-flex
                    class="d-flex mr-4 justify-end font-12"
                    v-if="currentRoom"
                  >
                    <span class="mr-1"> รหัส:</span>
                    <span :style="{ color: colorRole() }">
                      {{ currentRoom.room_code }}
                    </span>
                  </v-flex>

                  <v-flex
                    v-if="$vuetify.breakpoint.mdAndDown && currentRoom"
                    shrink
                    class="d-flex ml-3 justify-end font-12 xxx"
                  >
                    <v-btn fab icon small @click="showDetail = !showDetail">
                      <v-icon :color="colorRole()">
                        mdi-file-document-outline
                      </v-icon>
                      <v-icon :color="colorRole()" class="ml-n2" small>
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
          </div>
        </div>

        <v-divider style="border: 4px solid #edf0f6" />

        <chat-window
          id="chatArea"
          :class="
            user.roles && user.roles.role_id === 3
              ? 'con__chat'
              : 'con__chat_staff'
          "
          :height="
            $vuetify.breakpoint.smAndUp
              ? 'calc(100vh - 212px)'
              : 'calc(100vh - 376px)'
          "
          :current-user-id="currentUserId"
          :rooms="rooms"
          @add-room="addRoom"
          :toggle-rooms-list="toggleRoom"
          :rooms-loaded="roomsLoaded"
          :message-actions="messageAction"
          :loading-rooms="loadingRooms"
          :messages="messages"
          :messages-loaded="messagesLoaded"
          @fetch-messages="onFetchMessages"
          @delete-message="deleteMessage"
          theme="light"
          :show-emojis="false"
          :show-reaction-emojis="false"
          :show-audio="false"
          :show-new-messages-divider="true"
          :showFiles="showFiles"
          @send-message="send"
          @open-file="openFile"
          @typing-message="typingMessage"
          accepted-files="image/png, image/jpeg, application/pdf, video/mp4"
        >
          <!-- Slot Room list -->
          <template #room-list-item="{ room }">
            <div style="width: 100%" class="py-4">
              <v-layout align-center>
                <v-flex shrink class="font-12 font-w-300">
                  <span style="color: #5e616f">รหัส: </span>
                  <span style="color: #6e49ec">{{ room.room_code }}</span>
                </v-flex>
                <v-spacer />
                <v-flex shrink class="font-12 text-black">
                  <div v-if="statusRoom(room) === 'ใหม่!'">
                    <v-btn
                      width="47"
                      height="22"
                      depressed
                      small
                      color="#F6C517"
                      dark
                    >
                      ใหม่!
                    </v-btn>
                  </div>
                  <div v-else-if="statusRoom(room) === 'ยังไม่พร้อมใช้งาน!'">
                    <v-btn height="22" depressed small color="#E5E5E5" dark>
                      ยังไม่พร้อมใช้งาน!
                    </v-btn>
                  </div>
                  <div v-else>{{ statusRoom(room) }}</div>
                </v-flex>
                <v-flex
                  shrink
                  class="font-12 ml-2"
                  v-if="
                    statusRoom(room) !== 'ใหม่!' &&
                    statusRoom(room) !== 'ยังไม่พร้อมใช้งาน!'
                  "
                >
                  <v-icon
                    small
                    style="font-size: 12px"
                    :color="room.end_room ? '#BCC4CB' : '#4090ff'"
                  >
                    mdi-checkbox-blank-circle
                  </v-icon>
                </v-flex>
              </v-layout>
              <v-layout align-center mt-1>
                <v-flex xs10 class="font-12 black_color font-w-500 ell-1">
                  {{ room.roomName }}
                </v-flex>
                <v-spacer />
                <v-flex
                  shrink
                  class="font-12 text-black"
                  v-if="room.lastMessage"
                >
                  {{ room.lastMessage.timestamp | moment("H:mm") }}
                </v-flex>
              </v-layout>
              <v-layout align-center mt-1>
                <v-flex
                  shrink
                  class="font-12 ell-1 black_color font-w-300"
                  v-if="room.lastMessage"
                >
                  <span
                    v-if="checkIsRead(room.users)"
                    class="font-w-500"
                    style="color: #4090ff"
                  >
                    {{ room.lastMessage.content }}
                  </span>
                  <span v-else>
                    {{ room.lastMessage.content }}
                  </span>
                </v-flex>
                <v-spacer />
                <v-flex shrink class="font-12" v-if="checkIsRead(room.users)">
                  <span class="alert__message font-10">NEW</span>
                  <!-- <v-icon small style="font-size: 12px" color="#80C843">
                    mdi-checkbox-blank-circle
                  </v-icon> -->
                </v-flex>
              </v-layout>
            </div>
          </template>

          <template #paperclip-icon>
            <v-img
              v-if="user.roles && user.roles.role_id === 3"
              class="mx-2"
              src="../../assets/images/chat/gallery-add2.png"
            ></v-img>
            <v-img
              v-else
              class="mx-2"
              src="../../assets/images/chat/gallery-add.png"
            ></v-img>
          </template>

          <!-- Slot Room Header -->
          <template #room-header>
            <v-card
              tile
              :color="
                user.roles && user.roles.role_id === 3 ? '#EBF7FF' : '#F3F2FF'
              "
              height="64"
              width="100%"
              class="d-flex"
            >
              <v-layout
                align-center
                class="px-3 font-12 black_color"
                v-if="
                  checkStatusDate(currentRoom) &&
                  currentRoom.appointmentChat.length > 0
                "
              >
                <v-flex xs9>
                  <v-layout align-center>
                    <v-flex shrink class="mr-3">
                      <v-btn icon @click="toogleRoomList">
                        <v-icon large> mdi-swap-horizontal </v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex shrink class="mr-3">
                      <v-icon>mdi-calendar-clock</v-icon>
                    </v-flex>
                    <v-flex class="ell-1">
                      <div>{{ currentRoom.roomName }}</div>
                      <div>
                        <a
                          :style="
                            user.roles && user.roles.role_id === 3
                              ? { color: '#4090FF' }
                              : { color: '#6E49EC' }
                          "
                          target="_blank"
                          :href="currentRoom.appointmentChat[0].zoom_url"
                        >
                          {{ currentRoom.appointmentChat[0].zoom_url }}
                        </a>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs3 class="text-right">
                  <div>
                    {{
                      currentRoom.appointmentChat[0].date | moment("DD-MM-YYYY")
                    }}
                  </div>
                  <div>
                    {{ currentRoom.appointmentChat[0].start_time }}-{{
                      currentRoom.appointmentChat[0].end_time
                    }}
                  </div>
                </v-flex>
              </v-layout>

              <div v-else class="px-3 d-flex align-center font-12 black_color">
                ยังไม่มีการนัดหมาย Zoom Meeting :
              </div>
            </v-card>
          </template>
        </chat-window>
      </v-flex>

      <!-- Chat Detail -->
      <v-flex
        v-if="$vuetify.breakpoint.lgAndUp"
        lg4
        class="block__case__detail black_color"
        style="position: relative"
      >
        <div class="">
          <div class="pa-5">
            <v-icon :color="colorRole()">mdi-file-document-outline</v-icon>
            <span class="font-18 font-w-500 mx-3" style="color: #5e616f">
              รายละเอียด Case
            </span>
          </div>

          <v-divider style="border: 4px solid #edf0f6" />

          <div class="pa-4" v-if="currentRoom">
            <div class="d-flex justify-space-between">
              <div class="font-w-500 font-12" style="color: #bcc4cb">
                วันเวลาที่สร้าง:
              </div>
              <div class="font-12">
                {{ currentRoom.created_at | moment("DD-MM-YYYY") }} |
                {{ currentRoom.created_at | moment("H:mm") }}
              </div>
            </div>

            <v-divider class="my-4" />

            <v-layout>
              <v-flex xs2 class="font-w-500 font-12" style="color: #bcc4cb">
                ชื่อ-สกุล:
              </v-flex>
              <v-flex xs9 class="font-12">
                <span v-if="currentRoom && currentRoom.create_by">
                  {{ currentRoom.create_by.first_name }}
                  {{ currentRoom.create_by.last_name }}
                </span>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs2 class="font-w-500 font-12" style="color: #bcc4cb">
                วิชาชีพ:
              </v-flex>
              <v-flex xs9 class="font-12">
                {{
                  currentRoom.create_by && currentRoom.create_by.profession
                    ? currentRoom.create_by.profession.title
                    : ""
                }}
              </v-flex>
            </v-layout>
            <v-layout class="font-12">
              <v-flex xs2 class="font-w-500 font-12" style="color: #bcc4cb">
                หน่วยงาน:
              </v-flex>
              <v-flex>
                {{
                  currentRoom.create_by && currentRoom.create_by.institution
                    ? currentRoom.create_by.institution.title
                    : ""
                }}
              </v-flex>
            </v-layout>

            <v-divider class="my-4" />

            <div class="font-16 font-w-500 my-4">
              {{ currentRoom.roomName }}
            </div>

            <v-layout class="my-3">
              <v-flex xs6 class="font-12">
                <v-layout>
                  <v-flex xs2 class="font-w-500 font-12" style="color: #bcc4cb">
                    เพศ:
                  </v-flex>
                  <v-flex>{{ currentRoom.gender }}</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6 class="font-12">
                <v-layout>
                  <v-flex xs3 class="font-w-500 font-12" style="color: #bcc4cb">
                    อายุ (ปี):
                  </v-flex>
                  <v-flex>{{ currentRoom.age }}</v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <div class="font-12 my-3">
              <div class="font-w-500 font-12" style="color: #bcc4cb">
                รายละเอียด/ลักษณะครอบครัว:
              </div>
              <div>{{ currentRoom.description }}</div>
            </div>

            <div class="font-12 my-3">
              <div class="font-w-500 font-12" style="color: #bcc4cb">
                ปัญหา/ความต้องการ:
              </div>
              <div>
                {{ currentRoom.issue_detail }}
              </div>
            </div>

            <div class="font-w-500 font-12 mb-2" style="color: #bcc4cb">
              รูปภาพประกอบ:
            </div>
            <div style="width: 100.65px">
              <v-img
                class="detail_image cursor-pointer"
                v-if="currentRoom.image"
                aspect-ratio="1"
                :src="currentRoom.image"
                @click="dialog = true"
              ></v-img>
              <v-img
                v-else
                aspect-ratio="1"
                src="../../assets/images/default-image.png"
              ></v-img>
            </div>

            <v-divider class="my-4" />

            <div>
              <div class="font-w-500 font-12 mb-1" style="color: #bcc4cb">
                ผู้เชี่ยวชาญ:
              </div>

              <div v-for="(item, i) in currentRoom.users" :key="i">
                <v-layout
                  class="mb-2"
                  align-center
                  v-if="
                    item &&
                    item.user &&
                    item.user.roles &&
                    (item.user.roles.role_id === 4 ||
                      item.user.roles.role_id === 5)
                  "
                >
                  <v-flex shrink class="pr-2 py-2">
                    <v-img
                      v-if="item.user && item.user.image_profile"
                      width="50"
                      aspect-ratio="1"
                      style="border-radius: 100%"
                      :src="checkImage(item.user.image_profile)"
                    ></v-img>
                    <v-btn
                      v-else
                      depressed
                      :color="colorRole()"
                      dark
                      width="50"
                      height="50"
                      fab
                    >
                      <v-icon large>mdi-account-outline</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex xs9>
                    <div class="font-12 font-w-500">
                      {{ item.user.first_name }}
                      {{ item.user.last_name }}
                    </div>
                    <div class="font-12">
                      {{
                        item.user.profession_other
                          ? item.user.profession_other
                          : item.user.profession
                          ? item.user.profession.title
                          : ""
                      }}
                      <span
                        v-if="
                          item.user.profession &&
                          item.user.profession.short_title
                        "
                      >
                        ({{ item.user.profession.short_title }})
                      </span>
                    </div>
                    <div class="font-12" :style="{ color: colorRole() }">
                      {{
                        item.user.institution_other
                          ? item.user.institution_other
                          : item.user.institution
                          ? item.user.institution.title
                          : ""
                      }}
                      <span
                        v-if="
                          item.user.institution &&
                          item.user.institution.short_title
                        "
                      >
                        ({{ item.user.institution.short_title }})
                      </span>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </div>
        </div>

        <div
          class="pa-4 card_btn_create_meeting"
          v-if="
            user &&
            user.roles &&
            user.roles.role_id !== 2 &&
            currentRoom &&
            !currentRoom.end_room
          "
        >
          <v-row justify="center">
            <v-col cols="auto">
              <v-btn
                class="btn_blue_gradient"
                @click="dialogFormCreateCaseMeeting = true"
                height="39"
              >
                <v-icon color="#fff">mdi-plus</v-icon>
                <span class="white--text font-w-500">สร้างห้องสนทนา</span>
              </v-btn>
              <!-- <v-btn
                :class="
                  currentRoom &&
                  currentRoom.appointment_chat &&
                  currentRoom.appointment_chat.zoom_url
                    ? 'btn_disable'
                    : 'btn_blue_gradient'
                "
                :disabled="
                  currentRoom &&
                  currentRoom.appointment_chat &&
                  currentRoom.appointment_chat.zoom_url
                    ? true
                    : false
                "
                @click="dialogFormCreateCaseMeeting = true"
                height="39"
              >
                <v-icon color="#fff">mdi-plus</v-icon>
                <span class="white--text font-w-500">สร้างห้องสนทนา</span>
              </v-btn> -->
            </v-col>
            <v-col cols="auto">
              <v-btn
                :disabled="currentRoom && currentRoom.users.length < 2"
                height="39"
                color="#5E616F"
                style="border-radius: 29px"
                @click="endRoom()"
              >
                <span class="white--text font-w-500 px-3">เสร็จสิ้น</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-flex>

      <!-- Detail mobile size -->

      <div
        v-else
        class="detail__mobile"
        :style="
          showDetail
            ? { transform: 'translateX(0)' }
            : { transform: 'translateX(380px)' }
        "
      >
        <!-- :class="showDetail ? 'show_detail' : ''" -->

        <div style="position: relative">
          <div class="">
            <v-layout class="pa-5 d-flex justify-space-around align-center">
              <v-flex>
                <v-icon :color="colorRole()">mdi-file-document-outline</v-icon>
                <span class="font-18 font-w-500 mx-3" style="color: #5e616f">
                  รายละเอียด Case
                </span>
              </v-flex>
              <v-flex shrink>
                <v-btn icon @click="showDetail = false">
                  <v-icon large>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>

            <v-divider style="border: 4px solid #edf0f6" />

            <div class="pa-4" v-if="currentRoom">
              <div class="d-flex justify-space-between">
                <div class="font-w-500 font-12" style="color: #bcc4cb">
                  วันเวลาที่สร้าง:
                </div>
                <div class="font-12">
                  {{ currentRoom.created_at | moment("DD-MM-YYYY") }} |
                  {{ currentRoom.created_at | moment("H:mm") }}
                </div>
              </div>

              <v-divider class="my-4" />

              <v-layout>
                <v-flex xs2 class="font-w-500 font-12" style="color: #bcc4cb">
                  ชื่อ-สกุล:
                </v-flex>
                <v-flex xs9 class="font-12">
                  <span v-if="currentRoom.create_by">
                    {{ currentRoom.create_by.first_name }}
                    {{ currentRoom.create_by.last_name }}
                  </span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs2 class="font-w-500 font-12" style="color: #bcc4cb">
                  วิชาชีพ:
                </v-flex>
                <v-flex xs9 class="font-12">
                  {{
                    currentRoom.create_by && currentRoom.create_by.profession
                      ? currentRoom.create_by.profession.title
                      : ""
                  }}
                </v-flex>
              </v-layout>
              <v-layout class="font-12">
                <v-flex xs2 class="font-w-500 font-12" style="color: #bcc4cb">
                  หน่วยงาน:
                </v-flex>
                <v-flex>
                  {{
                    currentRoom.create_by && currentRoom.create_by.institution
                      ? currentRoom.create_by.institution.title
                      : ""
                  }}
                </v-flex>
              </v-layout>

              <v-divider class="my-4" />

              <div class="font-16 font-w-500 my-4">
                {{ currentRoom.roomName }}
              </div>

              <v-layout class="my-3">
                <v-flex xs6 class="font-12">
                  <v-layout>
                    <v-flex
                      xs2
                      class="font-w-500 font-12"
                      style="color: #bcc4cb"
                    >
                      เพศ:
                    </v-flex>
                    <v-flex>{{ currentRoom.gender }}</v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs6 class="font-12">
                  <v-layout>
                    <v-flex
                      xs3
                      class="font-w-500 font-12"
                      style="color: #bcc4cb"
                    >
                      อายุ (ปี):
                    </v-flex>
                    <v-flex>{{ currentRoom.age }}</v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <div class="font-12 my-3">
                <div class="font-w-500 font-12" style="color: #bcc4cb">
                  รายละเอียด/ลักษณะครอบครัว:
                </div>
                <div>{{ currentRoom.description }}</div>
              </div>

              <div class="font-12 my-3">
                <div class="font-w-500 font-12" style="color: #bcc4cb">
                  ปัญหา/ความต้องการ:
                </div>
                <div>
                  {{ currentRoom.issue_detail }}
                </div>
              </div>

              <div class="font-w-500 font-12 mb-2" style="color: #bcc4cb">
                รูปภาพประกอบ:
              </div>
              <div style="width: 100.65px">
                <v-img
                  class="detail_image cursor-pointer"
                  v-if="currentRoom.image"
                  aspect-ratio="1"
                  :src="currentRoom.image"
                  @click="dialog = true"
                ></v-img>
                <v-img
                  v-else
                  aspect-ratio="1"
                  src="../../assets/images/default-image.png"
                ></v-img>
              </div>

              <v-divider class="my-4" />

              <div>
                <div class="font-w-500 font-12 mb-1" style="color: #bcc4cb">
                  ผู้เชี่ยวชาญ:
                </div>

                <div v-for="(item, i) in currentRoom.users" :key="i">
                  <v-layout
                    align-center
                    v-if="
                      item &&
                      item.user &&
                      item.user.roles &&
                      (item.user.roles.role_id === 4 ||
                        item.user.roles.role_id === 5)
                    "
                  >
                    <v-flex shrink class="pr-2 py-2">
                      <v-img
                        v-if="item.user && item.user.image_profile"
                        width="50"
                        aspect-ratio="1"
                        style="border-radius: 100%"
                        :src="item.user.image_profile"
                      ></v-img>
                      <v-btn
                        v-else
                        depressed
                        :color="colorRole()"
                        dark
                        width="50"
                        height="50"
                        fab
                      >
                        <v-icon large>mdi-account-outline</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex xs9>
                      <div class="font-12 font-w-500">
                        {{ item.user.first_name }}
                        {{ item.user.last_name }}
                      </div>
                      <div class="font-12">
                        {{
                          item.user.profession ? item.user.profession.title : ""
                        }}
                        <span
                          v-if="
                            item.user.profession &&
                            item.user.profession.short_title
                          "
                        >
                          ({{ item.user.profession.short_title }})
                        </span>
                      </div>
                      <div class="font-12" :style="{ color: colorRole() }">
                        {{
                          item.user.institution
                            ? item.user.institution.title
                            : ""
                        }}
                        <span
                          v-if="
                            item.user.institution &&
                            item.user.institution.short_title
                          "
                        >
                          ({{ item.user.institution.short_title }})
                        </span>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </div>
          </div>
          <div
            class="pa-4 card_btn_create_meeting"
            v-if="
              user &&
              user.roles &&
              (user.roles.role_id === 4 || user.roles.role_id === 5) &&
              currentRoom &&
              currentRoom.end_room === null
            "
          >
            <v-row justify="center">
              <v-col cols="auto">
                <v-btn
                  :class="
                    currentRoom && currentRoom.users.length < 2
                      ? 'btn_disable'
                      : 'btn_blue_gradient'
                  "
                  :disabled="currentRoom && currentRoom.users.length < 2"
                  @click="dialogFormCreateCaseMeeting = true"
                  height="39"
                >
                  <v-icon color="#fff">mdi-plus</v-icon>
                  <span class="white--text font-w-500">สร้างห้องสนทนา</span>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  :disabled="currentRoom && currentRoom.users.length < 2"
                  height="39"
                  color="#5E616F"
                  style="border-radius: 29px"
                  @click="endRoom()"
                >
                  <span class="white--text font-w-500 px-3">เสร็จสิ้น</span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-layout>

    <!-- Dialog ภาพประกอบ -->
    <div class="text-center">
      <v-dialog v-model="dialog" width="70%" v-if="currentRoom">
        <v-card>
          <v-img :src="currentRoom.image">
            <div class="d-flex justify-end">
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </div>
          </v-img>
        </v-card>
      </v-dialog>
    </div>

    <!-- Dialog สร้างนัดหมายเคส -->
    <div class="text-center form_create_meeting">
      <v-dialog v-model="dialogFormCreateCaseMeeting" width="50%">
        <v-card class="pt-6 pb-12 px-12" elevation="6">
          <div class="font-36 font-w-500 text-center">สร้างนัดหมายเคส</div>
          <v-divider />
          <template>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="my-4">
                <div class="mb-2">วันที่</div>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.date"
                      placeholder="เลือกวันที่"
                      readonly
                      :rules="[(v) => !!v || 'Date is required']"
                      outlined
                      dense
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </div>

              <div class="my-4">
                <div class="mb-2">ช่วงเวลา</div>
                <v-select
                  outlined
                  dense
                  flat
                  hide-details
                  :rules="[(v) => !!v || 'Time is required']"
                  :items="timeList"
                  placeholder="เลือกช่วงเวลา"
                  solo
                  v-model="form.time"
                ></v-select>
              </div>

              <!-- <div class="my-4">
                <div class="mb-2">Link Zoom</div>
                <v-text-field
                  v-model="form.link_zoom"
                  placeholder="Link Zoom"
                  required
                  dense
                  hide-details
                  outlined
                ></v-text-field>
              </div> -->

              <v-row class="mt-4">
                <!-- <v-col>
                  <v-btn
                    :disabled="!valid"
                    class="mr-4"
                    :class="valid ? 'btn_blue_gradient' : 'btn_disable'"
                    @click="validate('start')"
                    :dark="valid"
                    block
                    height="58"
                  >
                    <span class="font-24 font-w-500 white--text">
                      เริ่มการสนทนา
                    </span>
                  </v-btn>
                </v-col> -->
                <v-col>
                  <v-btn
                    :disabled="!valid"
                    class="mr-4"
                    :class="valid ? 'btn_purple_gradient' : 'btn_disable'"
                    @click="validate('create')"
                    :dark="valid"
                    block
                    height="58"
                  >
                    <span class="font-24 font-w-500 white--text">
                      สร้างการนัดหมาย
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </v-card>
      </v-dialog>
    </div>

    <Loading :loading="isLoading"></Loading>
  </div>
</template>
<script>
import Loading from "../../components/Loading.vue";
import PUSHER from "pusher-js";
import SERVICE from "../../services/index";

// ref. https://openbase.com/js/vue-advanced-chat
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import moment from "moment";
import { storage_path, pusher_key, pusher_cluster } from "../../util/Constant";

var Buffer = require("buffer").Buffer;
var zlib = require("zlib");

export default {
  components: {
    // CardList,
    ChatWindow,
    Loading,
  },
  data() {
    return {
      showDetail: false,
      drawer: null,
      timeList: [
        "08:00-09:00",
        "09:00-10:00",
        "10:00-11:00",
        "11:00-12:00",
        "12:00-13:00",
        "13:00-14:00",
        "14:00-15:00",
        "15:00-16:00",
        "16:00-17:00",
        "17:00-18:00",
        "18:00-19:00",
        "19:00-20:00",
        "20:00-21:00",
        "21:00-22:00",
      ],
      items: [
        { title: "Home", icon: "mdi-view-dashboard" },
        { title: "About", icon: "mdi-forum" },
      ],
      storage_path: storage_path,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      isLoading: false,
      menu: false,
      modal: false,
      time: null,
      menu2: false,
      valid: true,
      form: {
        date: "",
        time: "",
        // link_zoom: "",
      },
      dialog: false,
      dialogFormCreateCaseMeeting: false,
      showFiles: true,
      messageAction: [
        // {
        //   name: "deleteMessage",
        //   title: "Delete",
        //   onlyMe: true,
        // },
      ],
      roomAction: [],
      menuActions: [],
      // currentUserId: 2,
      currentRoom: null,
      rooms: [],
      roomsLoaded: true,
      messagesLoaded: false,
      loadingRooms: false,
      messages: [],

      chat: {
        username: "me",
        message: null,
      },
      pusher: null,
      channel: null,
      subscribeRoom: null,
      uploadImage: null,
      message: [],
      roomId: "",
      chatAreaHeight: 0,
      currentPage: 1,
      last_page: 0,
      curOffset: 0,
      typingMessageCache: "",
      showListRoom: true,
    };
  },
  mounted() {
    // จะโหลดข้อความเพิ่มเติมเมื่อ scroll ขึ้นไปด้านบน
    document
      .getElementById("messages-list")
      .addEventListener("scroll", this.onScroll);

    // เวลา focus ช่อง input จะทำการ reset
    document
      .getElementById("roomTextarea")
      .addEventListener("focus", this.resetUnreadCount);
    document.getElementById("roomTextarea").placeholder = "พิมพ์ข้อความ...";

    // add style display none to class name vac-svg-button if user role is not 2
    if (this.user.roles.role_id != 2) {
      document.querySelector(".vac-svg-button").style.display = "none";
    }
  },
  async created() {
    this.isLoading = true;
    this.loadingRooms = true;
    const { data } = await SERVICE.chat.getCases();
    // ถ้ายังไม่เคยสร้าง room ให้ไปหน้า create case meeting
    if (data.length == 0) {
      this.loadingRooms = false;
      this.isLoading = false;
      this.$router.push("/create-case");
    } else {
      this.rooms = await data;
      this.isLoading = false;

      this.loadingRooms = false;

      // listen pusher
      this.setMsg();
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    countRequest() {
      return this.$store.state.auth.countRequest;
    },
    currentUserId() {
      // return this.currentUserId;
      return this.$store.state.auth.user.id;
    },
    setCurrentRoom() {
      return this.currentRoom;
    },
    setListRoom() {
      return this.rooms;
    },
    headChatName() {
      if (this.currentRoom && this.currentRoom.create_by) {
        return (
          this.currentRoom.create_by.first_name.toUpperCase() +
          " " +
          this.currentRoom.create_by.last_name.toUpperCase() +
          " " +
          this.checkProfession(this.currentRoom.create_by) +
          " " +
          this.checkIsnstitution(this.currentRoom.create_by)
        );
      } else {
        return "";
      }
    },

    chanelRoom() {
      return this.currentRoom.roomId;
    },
    breakpoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "xs";
        case "sm":
          return "sm";
        case "md":
          return "md";
        case "lg":
          return "lg";
        case "xl":
          return "xl";
        default:
          return false;
      }
    },
  },

  watch: {
    // check load more message by paginate
    chatAreaHeight(val) {
      // ถ้าความสูงของ scroll ของกล่องข้อความ > 1
      if (val == 0 && this.currentPage < this.last_page) {
        this.currentPage += 1;
        // this.onFetchMessages();
        this.onLoadMessagesByPaginate();
      }
    },
  },

  methods: {
    checkImage(img) {
      var string = "https://";
      var image = "";
      if (img.includes(string)) {
        image = img;
      } else {
        image = storage_path + img;
      }
      return image;
    },
    checkProfession(user) {
      if (user.profession_other && user.profession) {
        return user.profession_other
          ? `(${user.profession_other})`
          : `(${user.profession.title})`;
      } else {
        return "";
      }
    },
    checkIsnstitution(user) {
      if (user.institution_other && user.institution) {
        return user.institution_other
          ? `(${user.institution_other})`
          : `(${user.institution.title})`;
      } else {
        return "";
      }
    },
    onScroll() {
      this.chatAreaHeight = document.getElementById("messages-list").scrollTop;
    },
    resetUnreadCount() {
      // set is_read
      this.rooms = this.rooms.map((room) => {
        if (this.currentRoom.roomId === room.roomId) {
          room.users.map((e) => {
            if (e.is_read === 0) {
              e.is_read = 1;
            }
            return e;
          });
        }
        return room;
      });
      SERVICE.chat.readMsg(this.currentRoom.roomId);
    },
    async setMsg() {
      // production
      this.pusher = await new PUSHER(pusher_key, {
        cluster: pusher_cluster,
      });

      this.channel = await this.pusher.subscribe("oscc-chat");
      // listen message
      this.channel.bind("send-message", async (data) => {
        const rooms = JSON.parse(
          zlib.inflateSync(Buffer.from(data.rooms, "base64")).toString()
        );
        const msg = JSON.parse(
          zlib.inflateSync(Buffer.from(data.message, "base64")).toString()
        );
        if (this.countRequest === 0) {
          this.rooms = rooms;
          this.messages = msg.data.sort(this.compare);
        }

        // console.log(this.user,this.rooms,'before');

        //แยกห้องออกจาก role ต่างๆ
        if (this.user.has_role == 'staff') {
          this.rooms = this.rooms.filter(item => {
            return item.created_by == this.user.id
          })
        }
        else {
          this.rooms = this.rooms.filter(item => {
            let count = item.users.filter(u => {
              return u.user_id == this.user.id
            })

            return count.length > 0
          })
        }

        // console.log(this.rooms,'after')
        // this.rooms = this.rooms.map((item) => {
        //   if (item.roomId === data.currentRoom.roomId) {
        //     item = data.currentRoom;
        //   }
        //   return item;
        // });

        // // Set message ไปที่ room ที่เลือกอยู่
        // console.log(data.message.senderId + " !== " + this.currentUserId);
        // if (
        //   this.currentRoom &&
        //   this.currentRoom.roomId === data.currentRoom.roomId
        // ) {
        //   if (data.message.senderId !== this.currentUserId) {
        //     data.message["avatar"] = this.storage_path + data.message.avatar;
        //     this.messages.push(data.message);
        //     console.log("this.messages", this.messages);
        //   }
        // }
      });
    },

    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    openFile(e) {
      console.log("helo", e);
    },

    // save message
    async send(e) {
      this.$store.commit("auth/add_count_request");
      // update status seen(อ่านแล้ว)
      this.rooms = this.rooms.map((r) => {
        if (r.roomId === e.roomId) {
          r.unreadCount = 0;
        }
        return r;
      });

      // Case Files Content
      var base64data = [];
      var files = [];
      if (e.files && e.files.length > 0) {
        for (const file of e.files) {
          var fileSize = file.size / 1000000;
          // check size image file
          if (
            file.extension === "jpg" ||
            file.extension === "jpeg" ||
            file.extension === "png"
          ) {
            if (fileSize > 2) {
              this.$swal({
                title: `รูปภาพมีขนาดใหญ่เกินไป (${fileSize.toFixed(2)} MB)`,
                text: "รูปที่แนะนำต้องไม่เกิน 2 MB",
                icon: "warning",
                confirmButtonText: "ตกลง",
              });
              return false;
            } else {
              let img = await this.blobToBase64(file.blob);
              base64data.push({
                file: img,
                name: file.name,
                size: file.size,
                extension: file.extension,
              });
              files.push({
                name: file.name,
                size: file.size,
                type: file.extension,
                url: file.localUrl,
                preview: file.localUrl,
                audio: true,
                duration: 14.4,
              });
            }
          }

          // check size video file
          if (file.extension === "mp4") {
            if (fileSize > 10) {
              this.$swal({
                title: `วีดีโอมีขนาดใหญ่เกินไป (${fileSize.toFixed(2)} MB)`,
                text: "วีดีโอที่แนะนำต้องไม่เกิน 10 MB",
                icon: "warning",
                confirmButtonText: "ตกลง",
              });
              return false;
            } else {
              let video = await this.blobToBase64(file.blob);
              base64data.push({
                file: video,
                name: file.name,
                size: file.size,
                extension: file.extension,
              });
              files.push({
                name: file.name,
                size: file.size,
                type: file.extension,
                url: file.localUrl,
                preview: file.localUrl,
              });
            }
          }
        }

        const data = {
          roomId: e.roomId,
          content: e.content,
          files: e.files,
          base64data: base64data,
          replyMessage: e.replyMessage,
          userId: this.currentUserId,
          timestamp: new Date(),
          // timestamp: new Date(
          //   Date.now() - new Date().getTimezoneOffset() * 60000
          // ).toISOString(),
        };
        this.addImageMessage(e, files);
        this.saveMessage(data);
      }
      // Case Text Content
      else {
        const data = {
          roomId: e.roomId,
          content: e.content,
          files: e.files,
          base64data: base64data,
          replyMessage: e.replyMessage,
          userId: this.currentUserId,
          timestamp: new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          ).toISOString(),
        };
        await this.addMessage(e);
        await this.saveMessage(data);
      }
    },

    async saveMessage(data) {
      await SERVICE.chat.sendMsg(data).then(() => {
        this.$store.commit("auth/remove_count_request");
        // setTimeout(() => {
        //   this.messages[this.messages.length - 1].saved = 1;
        // }, 1000);
        return true;
      });
    },

    endRoom() {
      this.$swal({
        title: "คุณแน่ใจไหม?",
        text: "คุณต้องการที่จะปิดการแชท?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4090FF",
        cancelButtonColor: "#BCC4CB",
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่ใช่",
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          SERVICE.chat
            .endRoom({ roomId: this.currentRoom.roomId })
            .then(() => {
              this.rooms = this.rooms.map((room) => {
                if (room.roomId === this.currentRoom.roomId) {
                  var date = new Date();
                  room.end_room = moment(date, "DD-MM-YYYY")
                    .add(7, "days")
                    .format("YYYY-MM-DD");

                  this.currentRoom = room;
                }
                return room;
              });
              this.checkDisableRoom(true);
              this.showFiles = false;
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);
            });
        }
      });
    },

    addImageMessage(e, files) {
      this.messages.push({
        avatar: this.user.image_profile,
        content: "",
        date: moment(new Date()).format("YYYY-MM-DD"),
        deleted: 0,
        disableActions: 0,
        disableReactions: 0,
        distributed: 0,
        failure: 0,
        indexId: null,
        room_id: e.roomId,
        saved: 0,
        seen: 0,
        senderId: this.currentUserId,
        system: 0,
        timestamp:
          moment(new Date()).format("YYYY-MM-DD") +
          " " +
          moment(new Date()).format("H:mm:ss"),
        updated_at:
          moment(new Date()).format("YYYY-MM-DD") +
          " " +
          moment(new Date()).format("H:mm:ss"),
        created_at:
          moment(new Date()).format("YYYY-MM-DD") +
          " " +
          moment(new Date()).format("H:mm:ss"),
        user_id: null,
        username: this.user.username,
        _id: new Date().getTime(),
        files: files,
      });

      setTimeout(() => {
        files.forEach((item, i) => {
          document.getElementsByClassName("vac-loader-wrapper")[
            i
          ].style.display = "none";

          document.getElementsByClassName("vac-blur-loading")[i].style.filter =
            "blur(0px)";
        });
      }, 3000);
    },

    addMessage(e) {
      this.messages.push({
        avatar: this.user.image_profile,
        content: e.content,
        date: moment(new Date()).format("YYYY-MM-DD"),
        deleted: 0,
        disableActions: 0,
        disableReactions: 0,
        distributed: 0,
        failure: 0,
        files: null,
        indexId: null,
        room_id: e.roomId,
        saved: 0,
        seen: 0,
        senderId: this.currentUserId,
        system: 0,
        timestamp:
          moment(new Date()).format("YYYY-MM-DD") +
          " " +
          moment(new Date()).format("H:mm:ss"),
        updated_at:
          moment(new Date()).format("YYYY-MM-DD") +
          " " +
          moment(new Date()).format("H:mm:ss"),
        created_at:
          moment(new Date()).format("YYYY-MM-DD") +
          " " +
          moment(new Date()).format("H:mm:ss"),
        user_id: null,
        username: this.user.username,
        _id: new Date().getTime(),
      });
    },

    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },

    async onFetchMessages(e) {
      console.log(e)
      // this.isLoading = true;
      this.messages = [];
      this.messagesLoaded = false;
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toogleRoomList();
      }
      this.currentPage = 1;
      this.last_page = "";
      this.currentRoom = e.room;
      // Check disable input
      if (e.room.end_room || e.room.users.length < 2) {
        this.checkDisableRoom(true);
        this.showFiles = false; // ซ่อนปุ่ม upload file
      } else {
        this.checkDisableRoom(false);
        this.showFiles = true; // แสดงปุ่ม upload file
      }
      console.log(this.rooms,'rooms')
      // set ว่าได้อ่านข้อความใหม่แล้ว
      this.rooms = this.rooms.map((r) => {
        console.log(r)
        if (r.roomId === e.room.roomId) {
          if (r.lastMessage) {
            r.lastMessage.new = false;
          }
        }
        return r;
      });

      this.currentPage = 1;
      const res = await SERVICE.chat.getMessageRoom({
        currentPage: this.currentPage,
        roomId: e.room.roomId,
      });

      if (e.room.roomId === (this.currentRoom && this.currentRoom.roomId)) {
        this.messages = [];
        res.data.forEach((message) => {
          const formattedMessage = this.formatMessage(e.room, message);
          // console.log("formattedMessage", formattedMessage);
          this.messages.unshift(formattedMessage);
        });
      }

      // this.messages = await res.data.sort(this.compare); // sort array and set message
      this.last_page = await res.last_page;
      this.currentPage = await res.current_page;

      this.messagesLoaded = true;
      this.isLoading = false;
    },

    formatMessage(room, message) {
      const formattedMessage = {
        ...message,
        ...{
          senderId: message.senderId,
          _id: message._id,
          // timestamp: message.timestamp,
          // date: message.timestamp,
          username: message.username,
          // room.create_by && room.create_by.name ? room.create_by.name : "",
          // avatar: message.avatar,
          distributed: false,
        },
      };
      if (message.replyMessage) {
        formattedMessage.replyMessage = {
          ...message.replyMessage,
          ...{
            senderId: message.replyMessage.senderId,
          },
        };
      }
      return formattedMessage;
    },

    checkDisableRoom(status) {
      var textarea = document.getElementById("roomTextarea");
      textarea.disabled = status;
      textarea.style.backgroundColor = status ? "#e2e2e2" : "#fff";
    },

    // โหลดข้อความแชทเพิ่มเติม
    async onLoadMessagesByPaginate(e) {
      this.messagesLoaded = false;
      const res = await SERVICE.chat.getMessageRoom({
        currentPage: this.currentPage,
        roomId: this.currentRoom.roomId,
      });

      // set loading scrollTop
      var firstElementWithClass = document.querySelector(
        ".vac-message-wrapper"
      );

      // todo หาวิธีเอาเลขมาลบ
      if (firstElementWithClass) {
        document.getElementById("messages-list").scrollTop =
          firstElementWithClass.offsetTop - 120;
      }

      res.data.forEach((message) => {
        const formattedMessage = this.formatMessage(this.currentRoom, message);
        this.messages.unshift(formattedMessage);
      });
      // this.messages = res.data.sort(this.compare).concat(this.messages);

      this.messagesLoaded = true;
    },
    compare(a, b) {
      if (a._id < b._id) {
        return -1;
      }
      if (a._id > b._id) {
        return 1;
      }
      return 0;
    },
    addRoom() {
      this.$router.push("/create-case");
    },
    toggleRoom({ opened }) {},
    statusRoom(room) {
      // check ว่ามีผู้เชี่ยวชาญแล้วหรือยัง
      console.log(room,'status')
      if (room.users.length > 1) {
        if (room.lastMessage || room.end_room) {
          if (!room.end_room) {
            return "อยู่ระหว่างการปรึกษา";
          }
          var dateNow = moment(new Date(), "YYYY-MM-DD");
          var a = moment([
            moment(room.end_room).format("YYYY"),
            moment(room.end_room).format("M"),
            moment(room.end_room).format("D"),
          ]);
          var b = moment([
            moment(dateNow).format("YYYY"),
            moment(dateNow).format("M"),
            moment(dateNow).format("D"),
          ]);

          return `(หมดอายุใน ${a.diff(b, "days")} วัน) เสร็จสิ้น`;
        } else if (!room.end_room) {
          return "อยู่ระหว่างการปรึกษา";
        } else {
          return "ใหม่!";
        }
      } else {
        return "ยังไม่พร้อมใช้งาน!";
      }
    },
    async deleteMessage({ message, roomId }) {
      console.log(message, roomId);
    },
    checkTypeFile(file) {
      console.log(file);
    },
    typingMessage({ message, roomId }) {
      // console.log(message, roomId);
    },
    toogleRoomList() {
      this.showDetail = false;
      var e = document.querySelector(".vac-rooms-container");
      this.showListRoom = !this.showListRoom;
      if (!this.showListRoom) {
        e.style.display = "none";
      } else {
        e.style.display = "block";
      }
    },

    // Create Form meeting Zoom
    validate(type) {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        if (type === "create") {
          this.form["room_id"] = this.currentRoom.roomId;
          SERVICE.chat
            .createAppointmentChat(this.form)
            .then(async (res) => {
              this.isLoading = false;
              if (res.status === 201) {
                // console.log("createAppointmentChat ", res);
                this.form = {
                  date: "",
                  time: "",
                };
                this.dialogFormCreateCaseMeeting = false;
                this.$refs.form.resetValidation();
                this.$swal({
                  title: "สร้างนัดหมายเคสเรียบร้อยแล้ว",
                  icon: "success",
                }).then(async () => {
                  this.isLoading = true;
                  const { data } = await SERVICE.chat.getCases();
                  this.rooms = await data;
                  // console.log("before ", this.rooms, this.currentRoom.roomId);
                  this.currentRoom = this.rooms.find(
                    (i) => i.roomId === this.currentRoom.roomId
                  );
                  // console.log("after currentRoom", this.currentRoom);
                  this.isLoading = false;
                });
              } else {
                this.$swal({
                  title: "ไม่สามารถสร้างนัดหมายเคสได้",
                  text: res.response.data.message,
                  icon: "error",
                });
              }
            })
            .catch((err) => {
              this.isLoading = false;
            });
        } else {
          this.$swal({
            title: "Start Meeting Coming Soon",
            icon: "warning",
          });
        }
      }
    },
    colorRole() {
      if (this.user && this.user.roles && this.user.roles.role_id === 3) {
        return "#4090ff";
      } else {
        return "#009982";
      }
    },
    checkIsRead(users) {
      var user = users.find((item) => item.user_id === this.user.id);
      if (user && user.is_read === 0) {
        return true;
      } else {
        return false;
      }
    },

    checkStatusDate(currentRoom) {
      // check ว่ามี zoom ถูกสร้างไหม
      if (currentRoom && currentRoom.appointmentChat.length > 0) {
        const dateNow = moment(new Date()).format("YYYY-MM-DD");
        const timeNow = moment(new Date()).format("H:mm");

        // ถ้ายังไม่ถึงเวลา meeting
        if (dateNow < currentRoom.appointmentChat[0].date) {
          return true;
        }
        // ถ้าถึงเวลา meeting
        else if (dateNow === currentRoom.appointmentChat[0].date) {
          // เช็คเวลา
          // เวลาหมดแล้ว
          if (timeNow > currentRoom.appointmentChat[0].end_time) {
            return false;
          }
          // ถ้ายังไม่ถึงเวลานัดหมาย
          else {
            return true;
          }
        }
        // เลยเวลา meeting แล้ว
        else {
          return false;
        }
      }
      // ยังไม่ได้สร้าง zoom
      else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.block__card__list {
  box-shadow: 0px 3px 8px rgba(64, 144, 255, 0.25);
  z-index: 1;
  height: calc(100vh - 137px);
}
.block__case__detail {
  // box-shadow: 0px 3px 8px rgba(64, 144, 255, 0.25);
  height: calc(100vh - 137px);
  overflow: scroll;
}
.alert__meeting {
  background: #f3f2ff;
  filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.25));
}
.input__chat {
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: unset !important;
  }
}
.chat_list {
  overflow: scroll;
  height: calc(100vh - 300px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}
.chat_area {
  height: calc(100vh - 295px);
  background-color: #f9fafc;
  position: relative;
  overflow: scroll;
}
.box_message_me {
  font-size: 14px;
  max-width: 39%;
  background: #d8effe;
  border-radius: 20px 20px 0px 20px;
  margin: 10px 30px 10px 0px;
}
.box_message_friend_style1 {
  font-size: 14px;
  max-width: 39%;
  background: #d3d9e3;
  border-radius: 20px 20px 20px 0px;
  margin: 5px 30px 5px 0px;
}
.box_message_friend_style2 {
  font-size: 14px;
  max-width: 39%;
  background: #d3d9e3;
  border-radius: 20px 20px 20px 0px;
  margin: 5px 30px 5px 0px;
}
.vac-rooms-container {
  min-width: 350px;
  .vac-room-item {
    border-radius: 0px !important;
    margin-bottom: 0px;
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    margin-bottom: 5px;
    padding: 0 14px;
    position: relative;
    min-height: 71px;
    transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    border-bottom: 1px solid;
  }
}
.detail_image {
  background: #edf0f6;
  border-radius: 5px;
}
.head_case_list {
  width: 349px;
  border-right: 1px solid #edf0f6;
  // box-shadow: 0px 3px 8px rgba(64, 144, 255, 0.25);
}

// Custom Chat style
.vac-rooms-container .vac-room-selected,
.vac-rooms-container .vac-room-selected:hover {
  background: unset !important;
}

.vac-rooms-container .vac-room-selected {
  background: unset !important;
}
.vac-card-window {
  border-radius: unset;
  box-shadow: unset;
  -webkit-tap-highlight-color: unset;
}
.con__chat {
  .vac-message-wrapper .vac-message-current {
    background-color: #d8effe !important;
  }
  .vac-message-wrapper .vac-message-card {
    background-color: #d3d9e3;
    color: #5e616f;
  }
  .vac-box-footer {
    background: #fff;
  }
  .vac-col-messages .vac-container-scroll {
    background: #f9fafc;
  }
  #vac-icon-send {
    fill: #4090ff;
  }
  #vac-icon-paperclip {
    fill: #4090ff;
  }
}
.con__chat_staff {
  .vac-message-wrapper .vac-message-current {
    background-color: #d3d9e3 !important;
  }
  .vac-message-wrapper .vac-message-card {
    background-color: #dbefec;
    color: #5e616f;
  }
  .vac-box-footer {
    background: #fff;
  }
  .vac-col-messages .vac-container-scroll {
    background: #f9fafc;
  }
  #vac-icon-send {
    fill: #009982;
  }
  #vac-icon-paperclip {
    fill: #009982;
  }
}

textarea#roomTextarea {
  max-height: 150px !important;
  overflow: auto !important;
}

.message__card {
  padding: 10px;
  margin-bottom: 10px;
  background: #d8effe;
  border-radius: 20px 20px 0px 20px;
}
.card_btn_create_meeting {
  width: 100%;
  height: 140px;
  position: sticky;
  position: -webkit-sticky;
  display: flex;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 55.73%
  );
  align-items: end;
}
.btn_blue_gradient {
  background: linear-gradient(
    91.81deg,
    #4950ec -14.83%,
    #4090ff 48.24%,
    #6dcdec 107.49%
  );
  border-radius: 30px;
}
.btn_purple_gradient {
  background: linear-gradient(91.81deg, #c930ff -14.83%, #6e49ec 107.49%);
  border-radius: 30px;
}
.btn_disable {
  background-color: rgba(0, 0, 0, 0.12) !important;
  border-radius: 30px;
}
.form_create_meeting {
  .v-dialog {
    border-radius: 20px;
  }
}

.black_color {
  color: #5e616f;
}

.detail__mobile {
  position: absolute;
  z-index: 5;
  overflow: scroll;
  background: #fff;
  height: 100%;
  max-width: 375px;
  top: 0;
  box-shadow: -2px 0px 10px -7px #000000;
  right: 0;
  // transform: translateX(380px);
  transition: transform 100ms ease-in-out;
}

.alert__message {
  animation: color-change 1s infinite;
}

@keyframes color-change {
  0% {
    color: red;
  }
  50% {
    color: white;
  }
  100% {
    color: red;
  }
}

.loader {
  font-size: 21px;
  margin: 5px auto;
  text-indent: -9999em;
  text-align: right;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(
    left,
    #e3e3e3 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -webkit-linear-gradient(
    left,
    #e3e3e3 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -o-linear-gradient(left, #e3e3e3 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(
    left,
    #e3e3e3 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: linear-gradient(
    to right,
    #e3e3e3 10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #000000;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
