<template>
  <v-hover v-slot="{ hover }">
    <div @click="goto(id)" class="pointer" style="position: relative">
      <v-img
        :class="hover ? 'media-panel-img-hover' : 'media-panel-img'"
        v-if="image"
        :src="storage_path + image"
        :aspect-ratio="3 / 4"
      >
      </v-img>
      <v-img contain class="media-panel-img" v-else :src="noImage"></v-img>
      <ReadMore v-if="hover"></ReadMore>
      <p v-html="topic" class="overflow-ellipsis-2 mt-2 mh-50"></p>
      <div class="d-flex justify-space-between font-13" style="color: #5e616f">
        <div>
          <i class="bi bi-calendar-minus"></i>
          วันที่อัปเดตล่าสุด {{ date | moment("DD/MM/YYYY") }}
        </div>
        <a class="under-line">อ่านต่อ</a>
      </div>
    </div>
  </v-hover>
</template>

<script>
import moment from "moment";
import noImage from "../../assets/image/no-img.png";
import ReadMore from "../ReadMoreHover.vue";
import { storage_path } from "../../util/Constant";

export default {
  props: ["topic", "date", "image", "id"],
  components: {
    ReadMore,
  },
  data() {
    return {
      moment: moment,
      noImage: noImage,
      storage_path,
    };
  },
  methods: {
    goto(id) {
      this.$router.push(`/media/${id}`);
    },
  },
};
</script>

<style>
.media-panel-img {
  border-radius: 5px;
}

.media-panel-img-hover {
  filter: brightness(50%) !important;
  transition: 0.5s;
  border-radius: 10px;
}
</style>
