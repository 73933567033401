<template>
  <v-layout justify-center align-center class="bg__forgot_pass py-12">
    <v-flex xs10 sm7 md6 lg5>
      <Topic eng="Reset Password" thai="รีเซ็ตรหัสผ่าน" />

      <!-- form -->
      <v-form
        ref="form"
        class="mt-12 change_password__form"
        v-model="valid"
        lazy-validation
      >
        <div class="input__password">
          <label for="password">รหัสผ่านใหม่</label>
          <v-text-field
            v-model="form.new_password"
            outlined
            dense
            placeholder="ระบุรหัสผ่านใหม่"
            :rules="[
              (v) => !!v || 'ระบุรหัสผ่านใหม่',
              (v) =>
                (v && v.length >= 6) ||
                'รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร',
            ]"
            :append-icon="
              form.new_password
                ? new_password_val
                  ? 'mdi-eye'
                  : 'mdi-eye-off'
                : ''
            "
            @click:append="() => (new_password_val = !new_password_val)"
            :type="new_password_val ? 'password' : 'text'"
          />
        </div>
        <div class="input__password">
          <label for="password">ยืนยันรหัสผ่านใหม่</label>
          <v-text-field
            v-model="form.new_confirm_password"
            outlined
            dense
            placeholder="ระบุยืนยันรหัสผ่านใหม่"
            :rules="[
              (v) => !!v || 'ระบุยืนยันรหัสผ่านใหม่',
              (v) =>
                (v && v.length >= 6) ||
                'รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร',
            ]"
            :append-icon="
              form.new_confirm_password
                ? new_confirm_password_val
                  ? 'mdi-eye'
                  : 'mdi-eye-off'
                : ''
            "
            @click:append="
              () => (new_confirm_password_val = !new_confirm_password_val)
            "
            :type="new_confirm_password_val ? 'password' : 'text'"
          />
        </div>

        <div class="mt-8 d-flex justify-center">
          <v-btn
            class="btn__login"
            :disabled="!valid"
            :dark="valid"
            @click="validate"
            height="58px"
          >
            <span class="font-24 font-w-500">รีเซ็ตรหัสผ่าน</span>
          </v-btn>
        </div>

        <div class="mt-4 text-center d-flex justify-center align-center">
          <span
            class="hover__underline font-24 font-w-500"
            style="color: #bcc4cb"
            @click="$router.push('/')"
          >
            กลับสู่หน้าหลัก
          </span>
        </div>
      </v-form>

      <Loading :loading="isLoading"></Loading>
    </v-flex>
  </v-layout>
</template>

<script>
import Topic from "../../components/Topic.vue";
import { domain, domain_frontend } from "../../util/Constant";
import Axios from "axios";
import Loading from "../../components/Loading.vue";
export default {
  components: { Topic, Loading },

  data() {
    return {
      valid: true,
      isLoading: false,
      current_password_val: String,
      new_password_val: String,
      new_confirm_password_val: String,
      form: {
        new_password: "",
        new_confirm_password: "",
        token: "",
      },
    };
  },
  async created() {
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.form.token = this.$route.query.token;
        Axios.post(`${domain}/reset-password`, this.form)
          .then((res) => {
            this.$swal({
              title: "ทำรายการเรียบร้อยแล้ว",
              text: "ระบบได้ทำการรีเซ็ตรหัสผ่านเรียบร้อยแล้ว",
              icon: "success",
              confirmButtonText: "ตกลง",
            }).then(() => {
              this.$router.push("/login");
            });
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error.response.data.message);
            this.$swal({
              title: "ทำรายการไม่สำเร็จ",
              text: error.response.data.message,
              icon: "error",
              confirmButtonText: "ตกลง",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
.bg__forgot_pass {
  background: linear-gradient(180deg, #ffffff 0%, #edf0f6 100%);
  min-height: calc(100vh - 136px);
  color: #5e616f;
}
.resetpassword__form {
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-top: 0px;
    margin-bottom: 0px !important;
  }
}

.btn__login {
  width: 342px;
  background: linear-gradient(
    91.81deg,
    #4950ec -14.83%,
    #4090ff 48.24%,
    #6dcdec 107.49%
  );
  border-radius: 30px;
}
</style>
