<template>
  <v-layout justify-center align-center class="bg__forgot_pass py-12">
    <v-flex xs10 sm7 md6 lg5>
      <Topic eng="Forgot Password" thai="ลืมรหัสผ่าน" />

      <!-- form -->
      <v-form
        ref="form"
        class="forgotpassword__form"
        v-model="valid"
        lazy-validation
      >
        <div class="mt-12">
          <label class="font-18 font-w-500">
            <span class="valid">* </span>อีเมล
          </label>
          <v-text-field
            outlined
            dense
            type="email"
            v-model="form.email"
            :rules="[(v) => !!v || 'ระบุอีเมล']"
            placeholder="ระบุอีเมล"
          />
        </div>

        <div class="mt-12 d-flex justify-center">
          <v-btn
            class="btn__login"
            :disabled="!valid"
            :dark="valid"
            @click="validate"
            height="58px"
          >
            <span class="font-24 font-w-500">ส่ง</span>
          </v-btn>
        </div>

        <div class="mt-4 text-center font-24 font-w-500" style="color: #4090ff">
          <span class="hover__underline" @click="$router.push('/login')">
            ย้อนกลับ
          </span>
        </div>
      </v-form>

      <Loading :loading="isLoading"></Loading>
    </v-flex>
  </v-layout>
</template>

<script>
import Topic from "../../components/Topic.vue";
import { domain, domain_frontend } from "../../util/Constant";
import Axios from "axios";
import Loading from "../../components/Loading.vue";
export default {
  components: { Topic, Loading },

  data() {
    return {
      valid: true,
      isLoading: false,
      form: {
        email: "",
        url: "",
      },
    };
  },
  async created() {
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.form.url = domain_frontend + "/reset-password";
        Axios.post(`${domain}/forgot-password`, this.form)
          .then(() => {
            this.$swal({
              title: "ทำรายการเรียบร้อยแล้ว",
              text: "ระบบได้ทำการส่งลิ้งค์เปลี่ยนรหัสผ่านไปในอีเมลของคุณ กรุณาตรวจสอบ",
              icon: "success",
              confirmButtonText: "ตกลง",
            }).then(() => {
              this.$router.push("/login");
            });
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error.response);
          });
      }
    },
  },
};
</script>

<style lang="scss">
.bg__forgot_pass {
  background: linear-gradient(180deg, #ffffff 0%, #edf0f6 100%);
  min-height: calc(100vh - 136px);
  color: #5e616f;
}
.forgotpassword__form {
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-top: 0px;
    margin-bottom: 0px !important;
  }
}

.btn__login {
  width: 342px;
  background: linear-gradient(
    91.81deg,
    #4950ec -14.83%,
    #4090ff 48.24%,
    #6dcdec 107.49%
  );
  border-radius: 30px;
}
</style>
