import Service from "../service";
const url = "knowledge-media";
const media = {
  show(id) {
    return Service.get(`${url}/${id}`);
  },
  get(data) {
    return Service.get("knowledge-media-category", { params: data });
  },
  getCategory(data) {
    return Service.get("knowledge-media-category", { params: data });
  },
};
export default media;
