var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{nativeOn:{"mouseleave":function($event){return _vm.mouseLeave.apply(null, arguments)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$route.name !== 'ChatView' &&
      _vm.$route.name !== 'ForgotPassword' &&
      _vm.$route.name !== 'ResetPassword' &&
      _vm.$route.name !== 'Login' &&
      _vm.$route.name !== 'Register'
    ),expression:"\n      $route.name !== 'ChatView' &&\n      $route.name !== 'ForgotPassword' &&\n      $route.name !== 'ResetPassword' &&\n      $route.name !== 'Login' &&\n      $route.name !== 'Register'\n    "}],staticClass:"navigation__chat"},[_c('v-btn',{staticClass:"pa-4 btn__chat",attrs:{"to":"/chat","color":"#4090ff","height":"90","width":"90"}},[_c('div',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-img',{attrs:{"width":"34","height":"34","contain":"","aspect-ratio":"1","src":require("./assets/icon/chat_icon_white.png")}})],1),_c('div',{staticClass:"white--text font-12"},[_vm._v("ปรึกษา Case")])])])],1),_c('v-main',[(
        _vm.$route.name !== 'Login' &&
        _vm.$route.name !== 'Register' &&
        _vm.$route.name !== 'ForgotPassword' &&
        _vm.$route.name !== 'ResetPassword'
      )?_c('Header'):_vm._e(),_c('div',{staticClass:"min-height"},[_c('router-view',{key:_vm.$route.fullPath})],1),_c('Footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }