<template>
  <v-breadcrumbs :items="items" class="d-flex align-center">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :href="item.href">
        <div v-html="item.text" style="padding: 0px"></div>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
//import {menu} from '../util/Constant'
export default {
  props: ["items"],
};
</script>

<style>
.v-breadcrumbs {
  padding: 0px 0px 0px 0px !important;
}
</style>
