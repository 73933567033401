import AuthService from "../AuthService";

const chat = {
  getCases() {
    return AuthService.get(`/cases`).then((res) => res);
  },
  generateRoomCode() {
    return AuthService.get(`/room-code`).then((res) => res);
  },
  createCase(payload) {
    return AuthService.post(`/cases`, payload).then((res) => res);
  },
  readMsg(payload) {
    return AuthService.get(`/read-msg?roomId=${payload}`).then((res) => res);
  },
  getMessageRoom(payload) {
    return AuthService.get(
      `/msg-room?page=${payload.currentPage}&room_id=${payload.roomId}`
    ).then((res) => res);
  },
  sendMsg(payload) {
    return AuthService.post(`/send-msg`, payload).then((res) => res);
  },
  endRoom(payload) {
    return AuthService.post(`/end-room`, payload).then((res) => res);
  },
  testSendMsg(payload) {
    return AuthService.post(`/test-send-msg`, payload).then((res) => res);
  },
  createAppointmentChat(payload) {
    return AuthService.post(`/save-appointment-chat`, payload).then(
      (res) => res
    );
  },
};

export default chat;
