import AuthService from "../AuthService";
import Service from "../service";

const cases = {
  getCases() {
    return AuthService.get(`/get-cases`).then((res) => res);
  },
  getCaseAppointment(filter) {
    return AuthService.get(
      `/get-case-mentor?mentor_id=${filter ? filter : ""}`
    ).then((res) => res);
  },
  getAppointmentCaseMentor(filter) {
    return AuthService.get(
      `/get-appointment-case-mentor?mentor_id=${filter ? filter : ""}`
    ).then((res) => res);
  },
  getPersonalAppointmentCaseMentor(filter) {
    return AuthService.get(
      `/get-personal-appointment-cases-mentor?mentor_id=${filter ? filter : ""}`
    ).then((res) => res);
  },
  getAppointmentCases(page) {
    return AuthService.get(`/get-appointment-cases?page=${page}`).then(
      (res) => res
    );
  },
  getPersonalAppointmentCases(page) {
    return AuthService.get(`/get-personal-appointment-cases?page=${page}`).then(
      (res) => res
    );
  },
  getCourses(page) {
    return AuthService.get(`/get-courses?page=${page}`).then((res) => res);
  },
  getConsultants() {
    return AuthService.get(`/get-consultants`).then((res) => res);
  },
  saveAppointmentList(payload) {
    return AuthService.post(`/save-appointment-list`, payload).then(
      (res) => res
    );
  },
  savePersonalAppointment(payload) {
    return AuthService.post(`/save-personal-appointment`, payload).then(
      (res) => res
    );
  },
  appointmentList(payload) {
    return AuthService.get(
      `/appointment-list?m=${payload.m}&mentor_id=${
        payload.filter ? payload.filter : ""
      }`
    ).then((res) => res);
  },
  getMentorList() {
    return AuthService.get(`/get-mentor-list`).then((res) => res);
  },
  cancelAppointmentList(payload) {
    return AuthService.post("/cancel-appointment-list", payload).then(
      (res) => res
    );
  },
};

export default cases;
