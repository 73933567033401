import Service from '../../services/index'

const state = {
    paginate: {
        page: 0,
        itemsPerPage: 0,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0
    },
    eventAndNewsList: [],
    eventAndNews: null
}

const getters = {}
const actions = {
    // eslint-disable-next-line no-unused-vars
    get({ commit }, data) {
        return Service.eventAndNews.get(data).then((response) => {
            commit("SET_EVENT_AND_NEWS_LIST", response.data);
            commit('SET_PAGINATE', {
                page: response.current_page,
                itemsPerPage: response.per_page,
                pageStart: response.from,
                pageStop: response.last_page,
                pageCount: Math.ceil(response.total / response.per_page)
            })
            return response.data;
        })
    },
    show({ commit }, model) {
        return Service.eventAndNews.show(model).then((response) => {
            commit("SET_EVENT_AND_NEWS", response);
            return response;
        })
    },
}
const mutations = {
    SET_EVENT_AND_NEWS_LIST: (state, data) => {
        state.eventAndNewsList = data
    },
    SET_EVENT_AND_NEWS: (state, data) => {
        state.eventAndNews = data
    },
    SET_PAGINATE: (state, data) => {
        state.paginate = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


