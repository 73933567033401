<template>
  <v-layout justify-center align-center class="bg__change_pass">
    <v-flex xs9 sm6 md5 lg4>
      <Topic eng="Change Password" thai="เปลี่ยนรหัสผ่าน" />

      <!-- form -->
      <v-form
        ref="form"
        class="mt-12 change_password__form"
        v-model="valid"
        lazy-validation
      >
        <div class="input__password">
          <label for="password">รหัสผ่านปัจจุบัน</label>
          <v-text-field
            v-model="form.current_password"
            outlined
            dense
            placeholder="ระบุรหัสผ่านปัจจุบัน"
            :rules="[(v) => !!v || 'ระบุรหัสผ่านปัจจุบัน']"
            :append-icon="
              form.current_password
                ? current_password_val
                  ? 'mdi-eye'
                  : 'mdi-eye-off'
                : ''
            "
            @click:append="() => (current_password_val = !current_password_val)"
            :type="current_password_val ? 'password' : 'text'"
          />
        </div>

        <div class="input__password">
          <label for="password">รหัสผ่านใหม่</label>
          <v-text-field
            v-model="form.new_password"
            outlined
            dense
            placeholder="ระบุรหัสผ่านใหม่"
            :rules="[
              (v) => !!v || 'ระบุรหัสผ่านใหม่',
              (v) =>
                (v && v.length >= 6) ||
                'รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร',
            ]"
            :append-icon="
              form.new_password
                ? new_password_val
                  ? 'mdi-eye'
                  : 'mdi-eye-off'
                : ''
            "
            @click:append="() => (new_password_val = !new_password_val)"
            :type="new_password_val ? 'password' : 'text'"
          />
        </div>
        <div class="input__password">
          <label for="password">ยืนยันรหัสผ่านใหม่</label>
          <v-text-field
            v-model="form.new_confirm_password"
            outlined
            dense
            placeholder="ระบุยืนยันรหัสผ่านใหม่"
            :rules="[
              (v) => !!v || 'ระบุยืนยันรหัสผ่านใหม่',
              (v) =>
                (v && v.length >= 6) ||
                'รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร',
            ]"
            :append-icon="
              form.new_confirm_password
                ? new_confirm_password_val
                  ? 'mdi-eye'
                  : 'mdi-eye-off'
                : ''
            "
            @click:append="
              () => (new_confirm_password_val = !new_confirm_password_val)
            "
            :type="new_confirm_password_val ? 'password' : 'text'"
          />
        </div>

        <div class="mt-8 d-flex justify-center">
          <v-btn
            class="btn__login"
            :disabled="!valid"
            :dark="valid"
            @click="validate"
            height="58px"
          >
            <span class="font-24 font-w-500">เปลี่ยนรหัสผ่าน</span>
          </v-btn>
        </div>

        <div class="mt-4 text-center d-flex justify-center align-center">
          <span
            class="hover__underline font-24 font-w-500"
            style="color: #bcc4cb"
            @click="$router.push('/')"
          >
            กลับสู่หน้าหลัก
          </span>
        </div>
      </v-form>
      <Loading :loading="isLoading"></Loading>
    </v-flex>
  </v-layout>
</template>

<script>
import Topic from "../../components/Topic.vue";
import SERVICE from "../../services/index";
import Loading from "../../components/Loading.vue";
export default {
  components: { Topic, Loading },

  data() {
    return {
      valid: true,
      current_password_val: String,
      new_password_val: String,
      new_confirm_password_val: String,
      form: {
        current_password: "",
        new_password: "",
        new_confirm_password: "",
      },
      isLoading: false,
    };
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
  },
  methods: {
    async validate() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        SERVICE.auth
          .changePassword(this.form)
          .then((res) => {
            if (res.status === 201) {
              this.$swal({
                title: `ทำรายการสำเร็จ`,
                text: res.message,
                icon: "success",
              });
              this.form = {
                current_password: "",
                new_password: "",
                new_confirm_password: "",
              };
              this.$refs.form.resetValidation();
            } else {
              this.$swal({
                title: `ทำรายการไม่สำเร็จ`,
                text: res.message,
                icon: "error",
              });
            }
            this.isLoading = false;
          })
          .catch((res) => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.bg__change_pass {
  background: linear-gradient(180deg, #ffffff 0%, #edf0f6 100%);
  min-height: calc(100vh - 136px);
}
.btn__login {
  width: 342px;
  background: linear-gradient(
    91.81deg,
    #4950ec -14.83%,
    #4090ff 48.24%,
    #6dcdec 107.49%
  );
  border-radius: 30px;
}
.forgot__pass {
  color: #bcc4cb;
  cursor: pointer;
}
.forgot__pass a:hover {
  text-decoration: underline;
}
.input__password {
  .theme--light.v-input input,
  .theme--light.v-input textarea {
    color: #4090ff;
  }
}
.hover__underline:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
