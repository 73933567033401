import { render, staticRenderFns } from "./NewsPanel.vue?vue&type=template&id=884fb522&scoped=true"
import script from "./NewsPanel.vue?vue&type=script&lang=js"
export * from "./NewsPanel.vue?vue&type=script&lang=js"
import style0 from "./NewsPanel.vue?vue&type=style&index=0&id=884fb522&prod&lang=css"
import style1 from "./NewsPanel.vue?vue&type=style&index=1&id=884fb522&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "884fb522",
  null
  
)

export default component.exports