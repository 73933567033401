<template>
  <v-layout justify-center align-center class="bg__login">
    <!-- <div
      style="position: fixed; bottom: 0; right: 0"
      class="d-flex flex-column align-end justify-end pa-3"
    >
      <v-btn
        fab
        small
        max-width="25"
        max-height="25"
        class="mb-2"
        outlined
        @click="setData('staff')"
      >
        s
      </v-btn>
      <v-btn
        max-width="25"
        max-height="25"
        fab
        small
        outlined
        @click="setData('superprofession')"
      >
        sp
      </v-btn>
    </div> -->

    <v-flex xs9 sm6 md5 lg4>
      <Topic eng="Login" thai="เข้าสู่ระบบ" />

      <!-- form -->
      <v-form
        ref="form"
        class="mt-12 login__form"
        v-model="valid"
        lazy-validation
      >
        <div>
          <label for="email">อีเมล</label>
          <v-text-field
            v-model="form.email"
            outlined
            hide-details
            dense
            placeholder="ระบุอีเมล"
            :rules="[(v) => !!v || 'ระบุอีเมล']"
            @keyup.enter="validate"
          />
        </div>
        <div class="mt-4 input__password">
          <label for="password">รหัสผ่าน</label>
          <v-text-field
            v-model="form.password"
            outlined
            hide-details
            type="password"
            dense
            placeholder="ระบุรหัสผ่าน"
            :rules="[(v) => !!v || 'ระบุรหัสผ่าน']"
            @keyup.enter="validate"
          />
        </div>
        <div class="d-flex align-center font-18 mt-2 forgot__pass">
          <v-icon color="#bcc4cb" style="font-size: 18px" class="mr-1">
            mdi-alert
          </v-icon>
          <a href="/forgot-password" style="color: #bcc4cb"> ลืมรหัสผ่าน </a>
        </div>

        <v-layout class="mt-4 text-center d-flex justify-center align-center">
          <v-flex xs12 sm12 md9 lg8>
            <v-btn
              block
              class="btn_login"
              :disabled="!valid"
              :dark="valid"
              @click="validate"
              height="58px"
            >
              <span class="font-24 font-w-500">เข้าสู่ระบบ</span>
            </v-btn>
          </v-flex>
        </v-layout>

        <v-layout
          class="mt-4 text-center d-flex justify-center align-center"
          wrap
        >
          <v-flex xs12 sm5>
            <span
              class="hover__underline font-24 font-w-500"
              style="color: #bcc4cb"
              @click="$router.push('/')"
            >
              กลับสู่หน้าหลัก
            </span>
          </v-flex>
          <v-divider class="mx-2" vertical v-if="$vuetify.breakpoint.smAndUp" />
          <v-flex xs12 sm5>
            <span
              class="hover__underline font-24 font-w-500"
              style="color: #4090ff"
              @click="$router.push('/register')"
            >
              สมัครสมาชิก
            </span>
          </v-flex>
        </v-layout>

        <!-- <div class="mt-4 text-center d-flex justify-center align-center">
          <span
            class="hover__underline font-24 font-w-500"
            style="color: #bcc4cb"
            @click="$router.push('/')"
          >
            กลับสู่หน้าหลัก
          </span>
          <div class="mx-2" style="height: 25px; border: 1px solid">
            <v-divider vertical />
          </div>
          <span
            class="hover__underline font-24 font-w-500"
            style="color: #4090ff"
            @click="$router.push('/register')"
          >
            สมัครสมาชิก
          </span>
        </div> -->
      </v-form>
      <Loading :loading="isLoading"></Loading>
    </v-flex>
  </v-layout>
</template>

<script>
import Topic from "../../components/Topic.vue";
import { domain } from "../../util/Constant";
import Axios from "axios";
import Loading from "../../components/Loading.vue";
export default {
  components: { Topic, Loading },

  data() {
    return {
      valid: true,
      form: {
        email: "",
        password: "",
      },
      isLoading: false,
    };
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
  },
  methods: {
    async validate() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const { data } = await Axios.get(
          `${domain}/login?email=${this.form.email}&password=${this.form.password}`
        ).catch((error) => {
          this.isLoading = false;
          if (error.response.data === "โปรดรอการอนุมัติจากแอดมิน") {
            this.$swal({
              title: "เข้าสู่ระบบไม่สำเร็จ",
              text: error.response.data,
              icon: "error",
            });
          } else {
            this.$swal({
              title: "เข้าสู่ระบบไม่สำเร็จ",
              text: "กรุณาตรวจสอบอีเมลและรหัสผ่านใหม่อีกครั้ง",
              icon: "error",
            });
          }
        });
        this.isLoading = false;
        await this.$store.commit("auth/SET_AUTH", data);
        this.$router.push("/");
      }
    },
    async setData(role) {
      if (role === "superprofession") {
        this.form = {
          email: "kisra@dev.com",
          password: "password",
        };
      } else {
        this.form = {
          email: "kasem.t@kisracorp.com",
          password: "password",
        };
      }

      this.isLoading = true;
      const { data } = await Axios.get(
        `${domain}/login?email=${this.form.email}&password=${this.form.password}`
      ).catch((error) => {
        this.isLoading = false;
        if (error.response.data === "โปรดรอการอนุมัติจากแอดมิน") {
          this.$swal({
            title: "เข้าสู่ระบบไม่สำเร็จ",
            text: error.response.data,
            icon: "error",
          });
        } else {
          this.$swal({
            title: "เข้าสู่ระบบไม่สำเร็จ",
            text: "กรุณาตรวจสอบอีเมลและรหัสผ่านใหม่อีกครั้ง",
            icon: "error",
          });
        }
      });
      this.isLoading = false;
      await this.$store.commit("auth/SET_AUTH", data);
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
.bg__login {
  background: linear-gradient(180deg, #ffffff 0%, #edf0f6 100%);
  min-height: calc(100vh - 136px);
}
.btn_login {
  // width: 342px;
  background: linear-gradient(
    91.81deg,
    #4950ec -14.83%,
    #4090ff 48.24%,
    #6dcdec 107.49%
  );
  border-radius: 30px;
}
.forgot__pass {
  color: #bcc4cb;
  cursor: pointer;
}
.forgot__pass a:hover {
  text-decoration: underline;
}
.input__password {
  .theme--light.v-input input,
  .theme--light.v-input textarea {
    color: #4090ff;
  }
}
.hover__underline:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
