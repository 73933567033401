import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Media from "./modules/Media";
import auth from "./modules/Auth";
import Course from "./modules/Course"
import EventAndNews from "./modules/EventAndNews"
import FAQ from "./modules/FAQ"
import Search from "./modules/Search"
import Banner from "./modules/Banner"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Media,
    auth,
    Course,
    EventAndNews,
    FAQ,
    Search,
    Banner
  },
  plugins: [createPersistedState()],
});
