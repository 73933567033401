<template>
  <div>
    <Loading :loading="isLoading"></Loading>
    <!-- Desktop size -->
    <v-carousel
      v-if="$vuetify.breakpoint.mdAndUp"
      cycle
      height="auto"
      hide-delimiter-background
      hide-delimiters
      :show-arrows="false"
      class="carousel"
      v-model="currentIndex"
    >
      <v-carousel-item
        eager
        v-for="(item, i) in checkBanner(banner, 'desktop')"
        :key="i"
      >
        <div v-if="$vuetify.breakpoint.smAndDown">
          <v-img
            v-if="item.image_mb"
            eager
            class="mw-100"
            :src="banner_path + 'uploads/' + item.image_mb"
          ></v-img>
        </div>
        <div v-else>
          <v-img
            v-if="item.image_pc"
            eager
            class="mw-100"
            :src="banner_path + 'uploads/' + item.image_pc"
          ></v-img>
        </div>
      </v-carousel-item>
    </v-carousel>

    <!-- Mobile size -->
    <v-carousel
      v-else
      cycle
      height="auto"
      hide-delimiter-background
      hide-delimiters
      :show-arrows="false"
      class="carousel"
      v-model="currentIndex"
    >
      <v-carousel-item
        eager
        v-for="(item, i) in checkBanner(banner, 'mobile')"
        :key="i"
      >
        <div v-if="$vuetify.breakpoint.smAndDown">
          <v-img
            v-if="item.image_mb"
            eager
            class="mw-100"
            :src="banner_path + 'uploads/' + item.image_mb"
          ></v-img>
        </div>
        <div v-else>
          <v-img
            v-if="item.image_pc"
            eager
            class="mw-100"
            :src="banner_path + 'uploads/' + item.image_pc"
          ></v-img>
        </div>
      </v-carousel-item>
    </v-carousel>

    <v-container class="mw-1150">
      <div class="text-center mb-7">
        <v-btn
          class="mx-3 btn-blue-gradient"
          x-large
          rounded
          @click="$router.push('/create-case')"
        >
          <div class="mx-4 my-3">
            <div class="font-24">ปรึกษา Case ใหม่</div>
            <!-- <div class="mt-n2 font-12">(พร้อมใช้งานช่วงสิงหาคมนี้)</div> -->
          </div>
        </v-btn>
      </div>
      <v-layout wrap justify-center>
        <!-- :xs6="$vuetify.breakpoint.xs" -->
        <v-flex
          style="padding: 6px"
          v-for="(item, i) in type"
          @click="scrollToElement(item.value)"
          :key="i"
        >
          <MenuCard
            :image="item.value"
            :name="item.name"
            :desc="item.desc"
          ></MenuCard>
        </v-flex>
      </v-layout>

      <div class="my-10">
        <Topic ref="course" eng="Training Course" thai="หลักสูตรอบรม"></Topic>
      </div>
      <div>
        <CourseShortCut></CourseShortCut>
      </div>

      <div class="my-10">
        <Topic
          ref="event"
          eng="Events and News"
          thai="กิจกรรมและข่าวสาร"
        ></Topic>
      </div>
      <EventAndNewsShortCut></EventAndNewsShortCut>
      <div class="my-10">
        <Topic ref="media" eng="Knowledge Media" thai="สื่อความรู้"></Topic>
      </div>
      <div>
        <!-- <MediaShortCut></MediaShortCut> -->

        <v-row>
          <v-col
            class="my-2 px-2 py-0"
            cols="12"
            md="4"
            v-for="(item, i) in categorys"
            :key="i"
          >
            <div class="media__menu">
              <v-hover v-slot="{ hover }">
                <v-card
                  @click="filterCategory(i)"
                  class="px-2 py-2 pointer"
                  :elevation="hover ? 1 : 1"
                >
                  <v-layout class="d-flex align-center">
                    <v-flex>
                      <v-img
                        width="40px"
                        height="40px"
                        :src="require(`@/assets/mock/media_panel_${i}.png`)"
                      ></v-img>
                    </v-flex>
                    <v-flex>
                      <div
                        :class="
                          $vuetify.breakpoint.smAndDown
                            ? 'font-24 text-black'
                            : 'font-18 text-black'
                        "
                      >
                        {{ item }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-hover>
            </div>
          </v-col>
        </v-row>

        <div class="my-10" v-if="articles.length > 0">
          <Carousel
            v-if="isRender"
            :margin="2"
            :stagePadding="$vuetify.breakpoint.mdAndUp ? 0 : 40"
            :items="$vuetify.breakpoint.mdAndUp ? 4 : 1"
            :nav="false"
          >
            <MediaPanel
              style="padding: 6px"
              v-for="(item, i) in articles"
              :key="i"
              :image="item.image"
              :date="item.release_date"
              :topic="item.topic"
              :id="item.id"
            ></MediaPanel>
          </Carousel>
        </div>
        <div v-else class="my-10">
          <h1 class="text-center">ไม่พบสื่อความรู้</h1>
        </div>
      </div>

      <v-row class="my-10" justify="center">
        <v-col cols="auto">
          <v-btn
            min-width="228px"
            height="44px"
            @click="goto('media')"
            class="mx-3 btn-blue"
            rounded
          >
            ดูสื่อความรู้ทั้งหมด >
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MenuCard from "../components/MenuCard.vue";
import Carousel from "vue-owl-carousel";
import Topic from "../components/Topic.vue";
import CourseShortCut from "../components/Course/CourseShortCut.vue";
import MediaShortCut from "../components/Media/MediaShortCut.vue";
import EventAndNewsShortCut from "../components/News/EventAndNewsShortCut.vue";
import { banner_path } from "../util/Constant";
import MediaMenu from "../components/Media/MediaMenu.vue";
import MediaPanel from "../components/Media/MediaPanel.vue";
import Loading from "../components/Loading.vue";
export default {
  components: {
    MenuCard,
    CourseShortCut,
    Topic,
    // MediaShortCut,
    EventAndNewsShortCut,
    // MediaMenu,
    MediaPanel,
    Loading,
    Carousel,
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    this.isLoading = true;
    await Promise.all([this.getBanner(), this.getCategorys()]);
    this.isLoading = false;
  },
  methods: {
    async filterCategory(id) {
      this.isLoading = true;
      this.isRender = false;
      this.articles = [];
      const res = await this.$store.dispatch("Media/get", {
        category_id: id,
        paginate: 8,
      });
      this.articles = res.data;
      this.isLoading = false;
      this.isRender = true;
    },
    async getBanner() {
      await this.$store.dispatch("Banner/get").then((res) => {
        this.banner = res;
      });
    },
    async getCategorys() {
      const res = await this.$store.dispatch("Media/getCategory", {
        paginate: 4,
        // paginate: this.isPaginate ? 8 : 4,
      });
      this.categorys = res.group_name;
      this.articles = res.data;
    },
    menulastElement(index) {
      if (index + 1 === this.type.length && this.type.length % 2 !== 0) {
        return 12;
      } else {
        return 6;
      }
    },
    goto(route) {
      this.$router.push(route);
    },
    scrollToElement(topic) {
      if (topic === "member") {
        if (this.user.roles.role_id === 2) {
          this.$router.push("/personal-appointment");
        } else {
          this.$router.push("/mentor");
        }
      } else if (topic === "faq") {
        this.$router.push("/faq");
      } else {
        this.$refs[topic].$el.scrollIntoView({ behavior: "smooth" });
      }
    },
    onClickCarousel(val) {
      this.currentIndex = val;
      this.isActiveCarousel = val;
    },
    checkBanner(banner, t) {
      const b = [];
      if (t === "mobile") {
        banner.forEach((e) => {
          if (e.image_mb) {
            b.push(e);
          }
        });
      } else {
        banner.forEach((e) => {
          if (e.image_pc) {
            b.push(e);
          }
        });
      }
      return b;
    },
  },
  data() {
    return {
      categorys: [],
      isRender: true,
      isLoading: false,
      paginate: {
        page: 0,
        itemsPerPage: 0,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
      },
      articles: [],

      currentIndex: 0,
      isActiveCarousel: 0,
      banner_path: banner_path,
      banner: [
        {
          id: 1,
          image_pc: "",
          image_mb: "",
        },
        // {
        //   id: 1,
        //   image_desktop: require("@/assets/mock/Banner2desktop.png"),
        //   image_mobile: require("@/assets/mock/Banner2desktop.png"),
        // },
      ],
      type: [
        {
          name: "หลักสูตรอบรม",
          value: "course",
          desc: "",
        },
        {
          name: "กิจกรรมและข่าวสาร",
          value: "event",
          desc: "",
        },

        {
          name: "สื่อความรู้",
          value: "media",
          desc: "",
        },
        {
          name: "คำถามที่พบบ่อย",
          value: "faq",
          desc: "",
        },
        {
          name: "สำหรับสมาชิก",
          value: "member",
          desc: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.carousel {
  // border-bottom: 1px solid #e3e3e3;
  .theme--dark.v-btn.v-btn--icon {
    color: #474747;
  }
}

.owl-prev {
  width: 15px;
  height: 100px;
  position: absolute;
  top: 40%;
  margin-left: -20px;
  display: block !important;
  border: 0px solid black;
}

.owl-next {
  width: 15px;
  height: 100px;
  position: absolute;
  top: 40%;
  right: -25px;
  display: block !important;
  border: 0px solid black;
}
.owl-prev i,
.owl-next i {
  transform: scale(1, 6);
  color: #ccc;
}
</style>
