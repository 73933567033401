import Vue from "vue";
import VueFbCustomerChat from "vue-fb-customer-chat";

Vue.use(VueFbCustomerChat, {
  page_id: 115739691112897, //  change 'null' to your Facebook Page ID,
  theme_color: "#4090FF", // theme color in HEX
  locale: "en_US", // default 'en_US'
});

export default {};
